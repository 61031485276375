import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any): any {
    let date = new Date(value * 1000);
    return date.toLocaleDateString();
  }

}
