<section style="    position: fixed;
width: 100%;
z-index: 1;">
  <nav class=" header1 navbar navbar-expand-lg navbar-light navbar1">
    <div class="container" style="position: relative;    max-width: 1540px;">

      <img *ngIf="playerDetails?.isUpgraded" src="assets/img/image/vip.png" alt="" class="img-fluid" style="        width: 59px;
    position: absolute;
    top: -5px;
    left: 3px;">

      <a class="navbar-brand" [routerLink]="['/home']"><img src="assets/img/image/globexlogo.png" alt="" width="173"
          style="    margin-left: 37px;"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span><i class="fa-solid fa-bars"></i></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav" style="width: 100%;
        justify-content: center;">
          <li class="nav-item">
            Total Player : <b class="numberscount"> {{headerData.total_players}} </b>
          </li>
          <li class="nav-item ">
            Total Played: <b class="numberscount"> {{headerData.last_day_bet}}</b>
          </li>
          <li class="nav-item mainnav">
            <a href="{{explorerUrl}}token/{{tokenContract}}?a={{jackpotContract}}" target="_blank"
              class="justify-content-center">
              <p class="lists mb-0"> Contract Balance :
                <b class="numberscount text-truncate " *ngIf="busdbalance">{{busdbalance}}</b>
                <b class="numberscount text-truncate" *ngIf="!(busdbalance)">- -</b>
              </p>
            </a>
          </li>

          <!-- <li class="nav-item mainnav">
            <a   [routerLink]="['/backoffice']">
              <p  class="lists mb-0">My Account</p>
            </a>
          </li> -->


          <!-- <li class="nav-item mainnav" style="
                background-color: #FFF44A;
                border-radius: 5px;
                color: #000 !important;
                height: 25px;
              ">
            <a class="nav-link" *ngIf="!playerDetails?.isBuySecondNft"  (click)="upgatetoVip()">
              Upgrade To VIP
            </a>
            <a class="nav-link" *ngIf="playerDetails?.isBuySecondNft">
              Upgraded
            </a>
          </li> -->
          <li class="nav-item mainnav read-more-btn1  text-center" style="padding: 10px !important; width: auto;">
            <button  class="btn headings"  [matMenuTriggerFor]="menu" *ngIf="isConnected">{{address.slice(0,5)}}...{{address.slice(-5)}}</button>
            <button class="btn headings" *ngIf="!isConnected" (click)="connect()">Connect
              Wallet</button>
          </li>

          <mat-menu #menu="matMenu">
            <button mat-menu-item   (click)="logOut()" style="color: white;" >log out</button>
          </mat-menu>
          <li class="nav-item   "  routerLink="/buy-token">
            <button class=" mainnav reports-btn playbutton buyButtonPulse  text-center " style="    border-radius: 50px;
            height: 46px;
            line-height: 3px !important;" >
              Deposit
              <i style="font-size: 18px"></i>
            </button>

          </li>
          <li class="nav-item " routerLink="/withdrawal-token" *ngIf="showWithdrawal">
            <button class=" mainnav reports-btn playbutton buyButtonPulse  text-center " style="    border-radius: 50px;
            height: 46px;
            line-height: 3px !important;" >
            Withdrawal
            <i style="font-size: 18px"></i>
          </button>
          </li>
        </ul>

      </div>
    </div>
  </nav>

</section>


<!-- Modal for buy nft first -->
<!-- Modal -->
<div class="modal fade" id="staticBackdropTemp1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" >

      <div class="text-end">
        <button type="button" data-bs-dismiss="modal" aria-label="Close" class="closebutton">
          <img src="assets/img/image/close.png" alt="" />
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="address">
          <p class="conditionstext text-center ">My sponsor wallet:</p>
          <div class="" style="display: flex;
        margin: auto;justify-content: center;">
            <p class="text-truncate addresstext1 text-center col-12" style="width: auto;" >
              <span *ngIf="playerDetails?.playerId > 0">{{ this.playerDetails?.referral.slice(0, 5) }}...{{
                this.playerDetails?.referral.slice(-5)
                }}</span>
              <span *ngIf="playerDetails?.playerId == 0">{{ referalAddress.substring(0, 4) }}...{{
                referalAddress.substring(38)
                }}</span>
            </p>
            <button (click)="buyFirstNft()" data-bs-dismiss="modal" aria-label="Close" type="button"
              class="btn btn-primary reports-btn">Buy Lotto</button>

          </div>
        </div>
        <div *ngIf="!address">
          <p class="conditionstext text-center " style="font-size: 22px;"> Please Connect wallet first</p>

        </div>
      </div>

    </div>
  </div>
</div>
