import { Component, EventEmitter, Input, OnInit, Output ,ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { GlobalService } from 'src/core/services/global.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

@Component({
  selector: 'app-count-down-timer',
  templateUrl: './count-down-timer.component.html',
  styleUrls: ['./count-down-timer.component.scss']
})
export class CountDownTimerComponent implements OnInit {
  dateUpcoming:any;
  days:any;
  hours: string = '--';
  minutes: string = '--';
  seconds: string = '--';
  timeUp:boolean = false;
  @Output() timexpired = new EventEmitter();
  @Input() cssLotteryPosition:any;
  epocTime :any;
  constructor(private cd :ChangeDetectorRef,private globalService:GlobalService,
    public router:Router) { }

  async ngOnInit(): Promise<void> {

     await this.getBlockNumber();
    if(this.epocTime){
      this.dateUpcoming = this.epocTime;
    }

    let timestep = parseInt(await this.globalService.gameSpan())
    let game_startTime:any;
    let gameId:any;
    try {
      game_startTime = (await this.globalService.getGameStartTime());
      debugger
     gameId = parseInt(await this.globalService.lottoGameContract_new.getCurrentGameId())

    } catch (error) {

    }

    // let gameId = ( Math.round((Math.round(this.dateUpcoming)/timestep - Math.round(game_startTime)/timestep)) +1)
    let t = (Math.round(gameId)*timestep) + Math.round(game_startTime);

    let x = setInterval(()=>{

      var distance =  (t*1000) - ((this.dateUpcoming)*1000) ;

      //  this.days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString()
       this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString()
       this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString()
       this.seconds = Math.floor((distance % (1000 * 60)) / 1000).toString()

        this.hours   =   parseInt(this.hours)< 10 && parseInt(this.hours) >= 0 ? '0' + this.hours : this.hours;
        this.minutes =   parseInt(this.minutes) < 10 ? '0' + this.minutes : this.minutes;
        this.seconds =   parseInt(this.seconds)< 10 ? '0' + this.seconds : this.seconds;

      this.dateUpcoming++;
       if (parseInt(this.hours) < 0) {
        this.hours = '--';
        this.minutes ='--';
        this.seconds = '--';
        this.timexpired.emit('expired')
        clearInterval(x);

      }

    },1000)
  }

  async getBlockNumber(){
    var web3Provider = new Web3.providers.HttpProvider(environment.rpc_url);
    var web3 = new Web3( web3Provider );
    let block :any;
    let blockHash :any;
    await web3.eth.getBlockNumber().then((data:any)=>{
      block = data;
    });
    await web3.eth.getBlock(block).then((data:any)=>{
      blockHash = data?.hash;
      this.epocTime = data?.timestamp;

    });
  }

  }
