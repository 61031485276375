import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import AOS from 'aos';
import { GlobalService } from 'src/core/services/global.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Globex-lotto';
  isAdmin = false;
  constructor(private router: Router, private cs: GlobalService) {}
  ngOnInit() {
    AOS.init();
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.isAdmin = this.router.url.includes('admin');
      }
    });
    // this.sign()
  }

  // async sign() {
  //   await this.cs.init();
  // let sib = await this.cs.signMessage(
  //     `Welcome to nftMarketplace ${localStorage.getItem('address')} ${new Date()
  //       .toISOString()
  //       .slice(0, 10)}`
  //   );
  //   console.log(sib);
    
  // }
}
