import { Component, ElementRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/core/services/api.service';
import { GlobalService } from 'src/core/services/global.service';

export function validateWalletAddress(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value == ''
      ? null
      : ethers.utils.isAddress(control.value)
      ? null
      : { invalidAddress: true };
}

@Component({
  selector: 'app-buy-token',
  templateUrl: './buy-token.component.html',
  styleUrls: ['./buy-token.component.scss'],
})
export class BuyTokenComponent implements OnInit {
  transactionForm!: FormGroup;
  curretQr: any;
  upi_id: string = 'demo@okaxis.com';
  usdtPrice  = 0;
  isBuyTocken:number = 0;
  constructor(
    private fb: FormBuilder,
    private api_service: ApiService,
    private toster: ToastrService,
    private cs: GlobalService
  ) {}

  ngOnInit() {
    this.api_service.qr_code_show().subscribe((res: any) => {
      this.curretQr = res.image_path;
      this.upi_id = res.upi_id;
    });

    this.transactionForm = this.fb.group({
      usdt_value: [null],
      amount: [
        '',
        [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
      transaction_id: ['', [Validators.required]],
      wallet_address: ['', [Validators.required, validateWalletAddress()]],
    });

    this.api_service.usdt_show().subscribe({
      next: (res: any) => {
        this.usdtPrice = res.usdt_value;
      },
      error: () => {},
    });
  }

  get f() {
    return this.transactionForm.controls;
  }

  calculationOfUsdt() {
    const val = parseInt(this.f.amount.value ? this.f.amount.value : 0)/this.usdtPrice
    this.f.usdt_value.setValue(val);
  }

  onSubmit() {
    if (this.transactionForm.valid) {
      this.isBuyTocken = 1;
      this.api_service
        .user_deposite({ ...this.transactionForm.value })
        .subscribe({
          next: (res: any) => {
            this.cs.userDepositeApiCall$.next({isCallApi:true})
            this.toster.success(res.message);
            this.isBuyTocken = 0;
            this.transactionForm.reset();
          },
          error:(err:any)=>{
            this.isBuyTocken = 0;
          }
        });
    }
  }
}
