<section class="opacityview">
  <section class="main_sec">
    <!-- <div class="animate">
      <img src="assets/img/image/left-bg.png" alt="" class="leftside" > 
  
      <img src="assets/img/image/right-bg.png" alt="" class="rightside bounce-out-down">
    </div> -->

    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div style="position: relative">
            <h1 class="headingprice col-sm-12">
              <img src="assets/img/image/output-onlinegiftools.gif" alt="" style=" width: 100%;   border-radius: 50px;" >
              <!-- <div class="jackpotHeading headings">Jackpot</div> -->

              <strong
                ><animated-digit
                  [digit]="(busdbalance * 70) / 1e20"
                  [duration]="300"
                ></animated-digit>
                wUSDT</strong
              >
            </h1>
          </div>

          <div class="row">
            <h4 class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sidebar">
              {{ (busdbalance * 20) / 1e20 | number : "0.0-0" }}
              <img src="assets/img/image/wlogo.png" alt="" style="
              width: 10%;
              object-fit: contain;
          " >
              wUSDT- SPONSOR  
            </h4>
            <h4 class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              {{ (busdbalance * 10) / 1e20 | number : "0.0-0" }}
              <img src="assets/img/image/wlogo.png" alt="" style="
              width: 10%;
              object-fit: contain;
          " > wUSDT- ROLLOVER
            </h4>
          </div>

          <div class="row buttonrow" style="margin: 29px 0">
            <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-4">
              <div class="">
                <a [routerLink]="['/lottery']" class="playbutton">
                  Play Lotto</a
                >
              </div>
            </div>
            <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-4">
              <div class="">
                <!-- (click)="buyFirstNft()" -->
                <a
                  id="buyNftFirst"
                  class="neon-1 read-more-btn1 "
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropTemp"
                >
                  <i class="fa-solid fa-cart-shopping buyicons"></i>
                  BUY LOTTO <i class="fa-brands fa-searchengin buyicons"></i>
                  <i
                    *ngIf="isShowbuyFirstNftSpinner"
                    class="fa fa-spinner fa-spin"
                    style="font-size: 18px"
                  ></i>
                </a>
              </div>
            </div>
            <!--  -->
            <div
              class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-4"
              *ngIf="playerDetails?.playerId > 0"
            >
              <a
                *ngIf="!playerDetails?.isUpgraded"
                class="neon-2 neon-1 playbutton"
                (click)="buySecondNft()"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                UPGRADE TO VIP
                <i
                  *ngIf="isShowbuySecondNftSpinner"
                  class="fa fa-spinner fa-spin"
                  style="font-size: 18px"
                ></i>
              </a>

              <a *ngIf="playerDetails?.isUpgraded" class="neon-1 playbutton">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                UPGRADED
                <i
                  *ngIf="isShowbuySecondNftSpinner"
                  class="fa fa-spinner fa-spin"
                  style="font-size: 18px"
                ></i>
              </a>
            </div>
          </div>
         
         <div class=" dflexsection" >
          <div class="d-block m-2" >
            <p class="conditionstext " *ngIf="playerDetails?.playerId > 0">
              Upline Address:
            </p>
            <div
              class="contractaddress"
              style="width: 208px"
              *ngIf="playerDetails?.playerId == 0 || playerDetails?.playerId > 0"
            >
              <p class="text-truncate addresstext text-center col-12">
                <span *ngIf="playerDetails?.playerId > 0"
                  >{{ this.playerDetails?.referral.slice(0, 5) }}...{{
                    this.playerDetails?.referral.slice(-5)
                  }}</span
                >
                <span *ngIf="playerDetails?.playerId == 0"
                  >{{ _referalAddress.substring(0, 4) }}...{{
                    _referalAddress.substring(38)
                  }}</span
                >
              </p>
            </div>
          </div>

         <div class="d-block m-2" >
          <p class="conditionstext " *ngIf="playerDetails?.playerId > 0">
            My Referral Link:
          </p>
          <div class="contractaddress" *ngIf="playerDetails?.playerId > 0">
            <p class="text-truncate addresstext">
              {{ this.referalLinkurl }}{{ accountAddress }}
            </p>
            <span
              ><a type="button" (click)="copy(referalLinkurl + accountAddress)"
                ><i class="fa-solid fa-copy addresscopy" style=""></i></a
            ></span>
          </div>
         </div>
         </div>
        </div>
        <div class="col-md-5" style="margin: auto 0 auto auto">
          <div class="featured-auction-bg">
            <div class="row align-items-center">
              <div class="col-md-12 text-center align-item-center">
                <a [routerLink]="['/lottery']">
                  <div class="start-sec border-0 " >
                    <div class="row">
                      <div class="col-md-12">
                        <h2>DRAW STARTS IN</h2>
                        <div class="counter">
                          <div class="digital">
                            <app-count-down-timer
                              (timexpired)="isTimeExpired($event)"
                            ></app-count-down-timer>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-xl-12">
                            <h2>LAST DRAW</h2>
                            <div class="series" style="margin: 15px 0">
                              <ng-container
                                *ngIf="getBetLastResultNumber?.status == 10"
                              >
                                <ng-container
                                  *ngFor="
                                    let item of getBetLastResultNumber?.randomNos
                                  "
                                >
                                  <div class="circle headings">
                                    {{ item ?? "-" }}
                                  </div>
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="getBetLastResultNumber?.status != 10"
                              >
                                <div class="circle headings">--</div>
                                <div class="circle headings">--</div>
                                <div class="circle headings">--</div>
                                <div class="circle headings">--</div>
                                <div class="circle headings">--</div>
                                <div class="circle headings">--</div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mobile-view">
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-xl-12">
                            <h2>NEXT GAME ID :</h2>
                            <div class="series gamepad" style="">
                              <h1 class="mb-0 gameid">
                                {{ gameId }}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-12 col-sm-12  col-md-3 col-lg-3 col-xl-3">
                 <h2>latest Game ID</h2>
                 <p>{{latestGameId}}</p>
                </div> -->
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container p-0">
      <div class="row" style="width: 100%; margin: auto"></div>
    </div>
  </section>
  <section class="people_sec">
    <div class="container">
      <div class="row" style="width: 100%; margin: auto">
        <div class="col-md-10 me-auto ms-auto text-center align-item-center">
          <h3 class="sectionHeading">GENERAL HITS</h3>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 reportcolumn">
              <div
                class="card reportcard"
                (click)="getMatch3WinnerReport(3)"
                data-bs-toggle="modal"
                data-bs-target="#reportMatch3"
              >
                <h2>{{ match3winner ? match3winner : 0 }}</h2>
                <h5>3 HITS</h5>
                <a
                  data-bs-toggle="modal"
                  class="reportsname"
                  data-bs-target="#reportMatch3"
                  >REPORTS</a
                >
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 reportcolumn"
              data-bs-toggle="modal"
              data-bs-target="#reportMatch3"
            >
              <div class="card reportcard" (click)="getMatch3WinnerReport(4)">
                <h2>{{ mat4winnerr ? mat4winnerr : 0 }}</h2>
                <h5>4 HITS</h5>
                <!-- <a data-bs-toggle="modal" data-bs-target="#reportMatch4" class="btn btn-success">Reports</a> -->
                <a
                  data-bs-toggle="modal"
                  class="reportsname"
                  data-bs-target="#reportMatch3"
                  >REPORTS</a
                >
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 reportcolumn">
              <div
                class="card reportcard"
                (click)="getMatch3WinnerReport(5)"
                data-bs-toggle="modal"
                data-bs-target="#reportMatch3"
              >
                <h2>{{ match5winner ? match5winner : 0 }}</h2>
                <h5>5 HITS</h5>
                <!-- <a data-bs-toggle="modal" data-bs-target="#reportMatch5" class="btn btn-success">Reports</a> -->
                <a
                  data-bs-toggle="modal"
                  class="reportsname"
                  data-bs-target="#reportMatch3"
                  >REPORTS</a
                >
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 reportcolumn">
              <div
                class="card reportcard"
                (click)="getMatch3WinnerReport(6)"
                data-bs-toggle="modal"
                data-bs-target="#reportMatch3"
              >
                <h2>{{ match6Winner ? match6Winner : 0 }}</h2>
                <h5>6 HITS</h5>
                <!-- <a data-bs-toggle="modal" data-bs-target="#reportMatch6" class="btn btn-success">Reports</a> -->
                <a
                  data-bs-toggle="modal"
                  class="reportsname"
                  data-bs-target="#reportMatch3"
                  >REPORTS</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="user_sec">
    <div class="container">
      <div class="row">
        <div class="col-md-10 me-auto ms-auto text-center align-item-center">
          <h3 class="sectionHeading my-5">V.I.P. ACCESS LEADERBOARD</h3>

          <div class="user_card vip-card">
            <h3 class="mb-5 mt-5">
              {{ globalAmount / 1e18 / 1.25 | number : "0.0-0" }} of 800
            </h3>

            <div class="col-md-10 m-auto">
              <table class="darktable mobilview">
                <thead>
                  <tr>
                    <th class="currentsub1" style="width: 25%">AMOUNT</th>
                    <th class="currentsub1" style="width: 25%">TOP</th>
                    <th class="currentsub1" style="width: 25%">USER</th>
                    <th class="currentsub1" style="width: 25%">REFERRALS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of highestRoundArray[0]; let i = index"
                    [ngClass]="{ flash: item == accountAddress }"
                  >
                    <td scope="row p-0">
                      <span>{{
                        i == 0 ? "500 wUSDT" : i == 1 ? "200 wUSDT" : "100 wUSDT"
                      }}</span>
                    </td>
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.slice(0, 5) }}...{{ item.slice(-5) }}</td>
                    <td>{{ highestRoundArray?.counts[i] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="pagignation mt-5">
                  <div class="mb-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="user_sec d-block">
    <div class="container">
      <div class="row">
        <div class="col-md-10 me-auto ms-auto text-center align-item-center">
          <div class="currentearning">
            <h3 class="sectionHeading mb-5">CURRENT EARNINGS</h3>
            <div class="contenttable">
              <table class="darktable">
                <thead>
                  <th class="currentsub1" style="width: 35%">
                    ADDITIONAL EARNINGS
                  </th>
                  <th class="currentsub1" style="width: 30%">
                    READY TO WITHDRAW
                  </th>
                  <th class="currentsub1" style="width: 30%">WITHDRAWN</th>
                </thead>
                <tbody>
                  <tr>
                    <td class="currentsub" style="width: 35%">
                      <a
                        data-bs-toggle="modal"
                        (click)="openDirectSaleModal(1)"
                        data-bs-target="#DirectSale"
                      >
                        DIRECT SALE</a
                      >
                    </td>
                    <td class="currentsub" style="width: 30%">
                      {{ referalEaring?.directA / 1e18 }} wUSDT
                    </td>
                    <td class="currentsub" style="width: 30%">
                      {{ referalEaring?.directW / 1e18 }} wUSDT
                    </td>
                  </tr>
                  <tr>
                    <td class="currentsub">
                      <a
                        data-bs-toggle="modal"
                        (click)="open2to5LevelModal(2)"
                        data-bs-target="#to5Level"
                      >
                        2 - 5 LEVEL
                      </a>
                    </td>
                    <td class="currentsub">
                      {{ referalEaring?.indirectA / 1e18 }} wUSDT
                    </td>
                    <td class="currentsub">
                      {{ referalEaring?.indirectW / 1e18 }} wUSDT
                    </td>
                  </tr>
                  <tr>
                    <td class="currentsub">
                      <a
                        data-bs-toggle="modal"
                        (click)="openNft200Modal(3)"
                        data-bs-target="#nft200"
                      >
                        Vip Upgrade
                      </a>
                    </td>
                    <td class="currentsub">
                      {{
                        referalEaring?.nft200EarningA / 1e18 | number : "0.0-4"
                      }}
                      wUSDT
                    </td>
                    <td class="currentsub">
                      {{
                        referalEaring?.nft200EarningW / 1e18 | number : "0.0-4"
                      }}
                      wUSDT
                    </td>
                  </tr>
                  <tr>
                    <td class="currentsub">
                      <a
                        data-bs-toggle="modal"
                        (click)="openAccumulatedEarningModal(4)"
                        data-bs-target="#AccumulatedEarning"
                      >
                        Vip Bonus
                      </a>
                    </td>
                    <td class="currentsub">
                      {{
                        referalEaring?.accumulatEdearningsA / 1e18
                          | number : "0.0-4"
                      }}
                      wUSDT
                    </td>
                    <td class="currentsub">
                      {{
                        referalEaring?.accumulatEdearningsW / 1e18
                          | number : "0.0-4"
                      }}
                      wUSDT
                    </td>
                  </tr>
                  <tr>
                    <td class="currentsub">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#waitingToUnlock"
                      >
                        Amount on Wait
                      </a>
                    </td>
                    <td class="currentsub">
                      {{
                        playerDetails?.levelEarnings / 1e18 | number : "0.0-4"
                      }}
                      wUSDT
                    </td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
              <div class="col-sm-12 mb-3">
                <button
                  (click)="withdraw()"
                  class="reports-btn m-5"
                  style="width: auto"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="user_sec d-block">
    <div class="container">
      <div class="row">
        <div class="col-md-10 me-auto ms-auto text-center align-item-center">
          <h1 class="sectionHeading mb-5">REFERRAL STRUCTURE</h1>
          <div class="contenttable">
            <table class="darktable">
              <thead>
                <tr>
                  <td class="currentsub1" style="width: 35%">LEVEL</td>
                  <td class="currentsub1" style="width: 35%">
                    TOTAL REFERRALS
                  </td>
                  <td class="currentsub1" style="width: 35%">TOTAL AMOUNT</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of referalInfo?.referrals; let i = index">
                  <td class="levels">Level {{ i + 1 }}</td>
                  <td class="levels">{{ item }}</td>
                  <td class="levels">
                    {{ referalInfo?.referralE[i] / 1e18 | number : "0.0-0" }}
                  </td>
                </tr>

                <tr *ngIf="referalInfo?.length == 0">
                  <td colspan="3">No Data Found.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="earning_sec">
    <div class="container">
      <div class="row">
        <div
          class="col-md-10 me-auto ms-auto text-center align-item-center animate__animated"
        >
          <h3 class="sectionHeading mb-3">TOP USER EARNINGS</h3>
          <div
            class="row"
           
          >
          <div class="row"  style="
          border:2px solid #c7dcff;
          margin: 0;
          border-radius: 4px;
          padding: 34px 0;
        ">
            <div class="col-4 col-sm-12 col-md-4 col-lg-4 col-xl-4 earnings">
              <h3 class="earninghead">RANKING</h3>
              <p class="mb-0 earningitem">{{ rank.rank }}</p>
            </div>
            <div class="col-4 col-sm-12 col-md-4 col-lg-4 col-xl-4 earnings">
              <h3 class="earninghead">USER</h3>
              <p class="mb-0 earningitem">
                {{ accountAddress?.slice(0, 5) }}...{{
                  accountAddress?.slice(-5)
                }}
              </p>
            </div>
            <div
              class="col-4 col-sm-12 col-md-4 col-lg-4 col-xl-4 earnings border-0"
            >
              <h3 class="earninghead">TOTAL EARNING</h3>
              <p class="mb-0 earningitem" *ngIf="rank.total_earning / 1e18 >= 0">
                {{ rank.total_earning / 1e18 }}
              </p>
              <p
                class="mb-0 earningitem"
                *ngIf="!(rank.total_earning / 1e18 >= 0)"
              >
                ...
              </p>
            </div>
          </div>
           <!--  -->

            <div class="mt-5 contenttable col-sm-12 p-0">
              <div class="col-md-12">
                <table class="darktable text-center">
                  <thead>
                    <tr>
                      <th class="earningtable">RANKING</th>
                      <th class="earningtable">USER</th>
                      <th class="earningtable">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of topEarningUser">
                      <td>
                        {{ item.ranking }}
                      </td>
                      <td>
                        {{ item.walletAddress.slice(0, 5) }}...{{
                          item.walletAddress.slice(-5)
                        }}
                      </td>
                      <td>{{ item.winPrice / 1e18 }} wUSDT</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mb-3">
                <ngb-pagination
                  [(page)]="page"
                  [maxSize]="4"
                  [pageSize]="pageSize"
                  [collectionSize]="totalRanking"
                  (pageChange)="loadPage($event)"
                  class="justify-content-center"
                ></ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div
      class="modal fade"
      id="reportMatch3"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="text-end">
            <button
              type="button"
              #closebuttonreportMatch3
              data-bs-dismiss="modal"
              aria-label="Close"
              class="closebutton"
            >
              <img src="assets/img/image/close.png" alt="" />
            </button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="">Sr.No</th>
                  <th scope="col" class="">Users</th>
                  <th scope="col" class="">Earning</th>
                  <th scope="col" class="">Number</th>
                  <th scope="col" class="">Hash</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="isLoadingMatch3WinnerList == 1">
                  <tr
                    *ngFor="let item of match3WinnerList; let i = index"
                    class="modaltr"
                  >
                    <th scope="row" class="">{{ i + 1 }}</th>
                    <td class="">
                      {{ item.walletAddress.slice(0, 5) }}...{{
                        item.walletAddress.slice(-5)
                      }}
                    </td>
                    <td class="">{{ item.reward / 1e18 }}</td>
                    <td class="">{{ item.betsNumber }}</td>
                    <td class="">
                      <a
                        href="{{ explorerUrl }}tx/{{ item.transactionHash }}"
                        class=""
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ item.transactionHash.substring(0, 4) }}...{{
                          item.transactionHash.substring(60)
                        }}
                      </a>
                    </td>
                  </tr>
                </ng-container>
                <tr *ngIf="isLoadingMatch3WinnerList == 0" class="modaltr">
                  <td colspan="5" class="" style="border: none">Loading....</td>
                </tr>
                <tr *ngIf="isLoadingMatch3WinnerList == 2" class="modaltr">
                  <td colspan="5" class="" style="border: none">Not Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <!-- Modal  DirectSale-->
    <div
      class="modal fade"
      id="DirectSale"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="text-end">
            <button
              type="button"
              #DirectSale
              data-bs-dismiss="modal"
              aria-label="Close"
              class="closebutton"
            >
              <img src="assets/img/image/close.png" alt="" />
            </button>
          </div>
          <div class="modal-body">
            <h4>Direct Sale</h4>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="">Date</th>
                  <th scope="col" class="">Level</th>
                  <th scope="col" class="">Amount</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="isdirectSaleListLoaded == 1">
                  <tr *ngFor="let item of directSaleList" class="modaltr">
                    <td>{{ item.time * 1000 | date : "dd-MM-yyyy" }}</td>
                    <td>{{ item.status * 1 + 1 }}</td>
                    <td>{{ item.referralAmount }} wUSDT</td>
                  </tr>
                </ng-container>
                <tr *ngIf="isdirectSaleListLoaded == 0" class="modaltr">
                  <td colspan="2">Loading</td>
                </tr>
                <tr *ngIf="isdirectSaleListLoaded == 2" class="modaltr">
                  <td colspan="2">Not Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="to5Level"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="text-end">
            <button
              type="button"
              #to5Level
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="">Date</th>
                  <th scope="col" class="">Level</th>
                  <th scope="col" class="">Amount</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="isTwoTo5LevelListLoaded == 1">
                  <tr *ngFor="let item of twoTo5LevelList" class="modaltr">
                    <td>{{ item.time * 1000 | date : "dd-MM-yyyy" }}</td>
                    <td>{{ item.status * 1 + 1 }}</td>
                    <td>{{ item.referralAmount }} wUSDT</td>
                  </tr>
                </ng-container>
                <tr *ngIf="isTwoTo5LevelListLoaded == 0" class="modaltr">
                  <td colspan="2">Loading</td>
                </tr>
                <tr *ngIf="isTwoTo5LevelListLoaded == 2" class="modaltr">
                  <td colspan="2">Not Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="nft200"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="text-end">
            <button
              type="button"
              #nft200
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="">Date</th>
                  <th scope="col" class="">Amount</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="isnftTwoHundredListLoaded == 1">
                  <tr *ngFor="let item of nftTwoHundredList" class="modaltr">
                    <td>{{ item.time | dateFormat }}</td>
                    <td>{{ item.referralAmount }}</td>
                  </tr>
                </ng-container>
                <tr *ngIf="isnftTwoHundredListLoaded == 0" class="modaltr">
                  <td colspan="2">Loading</td>
                </tr>
                <tr *ngIf="isnftTwoHundredListLoaded == 2" class="modaltr">
                  <td colspan="2">Not Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="AccumulatedEarning"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="text-end">
            <button
              type="button"
              #AccumulatedEarning
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="">Date</th>
                  <th scope="col" class="">Amount</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="IsAccumulatedEarningListLoaded == 1">
                  <tr
                    *ngFor="let item of AccumulatedEarningList"
                    class="modaltr"
                  >
                    <td>{{ item.time | dateFormat }}</td>
                    <td>{{ item.referralAmount }}</td>
                  </tr>
                </ng-container>
                <tr *ngIf="IsAccumulatedEarningListLoaded == 0" class="modaltr">
                  <td colspan="2">Loading</td>
                </tr>
                <tr *ngIf="IsAccumulatedEarningListLoaded == 2" class="modaltr">
                  <td colspan="2">Not Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="waitingToUnlock"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="text-end">
            <button
              type="button"
              #waitingToUnlock
              data-bs-dismiss="modal"
              aria-label="Close"
              class="closebutton"
            >
              <img src="assets/img/image/close.png" alt="" />
            </button>
          </div>
          <div class="modal-body text-center">
            <h4>Amount On Hold</h4>
            <p style="color: #198754; font-size: 24px; font-weight: 600">
              Waiting to unlock
            </p>
            <p>10 Direct referrals needed</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdropTemp"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" >
          <div class="text-end">
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              class="closebutton"
            >
              <img src="assets/img/image/close.png" alt="" />
            </button>
          </div>
          <div class="modal-body">
            <div *ngIf="accountAddress">
              <p class="conditionstext text-center">My sponsor wallet:</p>

              <div
                class=""
                style="display: flex; margin: auto; justify-content: center"
              >
                <p class="text-truncate addresstext1 text-center col-12"style="width: auto;">
                  <span *ngIf="playerDetails?.playerId > 0"
                    >{{ this.playerDetails?.referral.slice(0, 5) }}...{{
                      this.playerDetails?.referral.slice(-5)
                    }}</span
                  >
                  <span *ngIf="playerDetails?.playerId == 0"
                    >{{ _referalAddress.substring(0, 4) }}...{{
                      _referalAddress.substring(38)
                    }}</span
                  >
                </p>
                <button
                  (click)="buyFirstNft()"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  class="btn btn-primary reports-btn"
                >
                  Buy Lotto
                </button>
              </div>
            </div>
            <div *ngIf="!accountAddress">
              <p class="conditionstext text-center" style="font-size: 22px">
                Please Connect wallet first
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>

