import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private ngxService: NgxUiLoaderService,
    private router:Router
  ) {}

  get getIsAuth(): boolean {
    const authToken = localStorage.getItem('signature')
    return authToken !== null ? true : false;
  }

logOut(){
  localStorage.removeItem('signature')
  this.router.navigate(['/admin/login'])
}
  getNftFromMoralis(userAddress: any, gameId: any): Observable<any> {
    this.ngxService.start();
    return new Observable<any>((observer) => {
      this.http
        .get(
          `${environment.apiUrl}moralisApiToItemNftList?contractAddress=${environment.nft_address}&userAddress=${userAddress}&gameId=${gameId}`
        )
        .subscribe({
          next: (res) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (e: any) => {
            observer.error(e);
            this.ngxService.stop();
          },
        });
    });
  }



  getActiveNftId(userAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable<any>((observer) => {
      this.http
        .get(
          `${environment.apiUrl}moralisApiNftHolding?contractAddress=${environment.nft_address}&userAddress=${userAddress}`
        )
        .subscribe({
          next: (res) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (e: any) => {
            observer.error(e);
            this.ngxService.stop();
          },
        });
    });
  }

  getBetResultList(userAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer) => {
      this.http
        .get(
          `${environment.apiUrl}getBetResultList?walletAddress=${userAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  getBetLastResult(userAddress: any, contractAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer) => {
      this.http
        .get(
          `${environment.apiUrl}moralisApiNftHolding?userAddress=${userAddress}&contractAddress=${contractAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  topEarningUser(page: number): Observable<any> {
    // this.ngxService.start();
    return new Observable((observer) => {
      this.http
        .get(`${environment.apiUrl}top_user_earning?page=${page}`)
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  admin_login(body: {
    username: string;
    signature: string;
    wallet_address: string;
  }) {
    return this.http.post(`${environment.apiUrl}admin_login`, body);
  }
  user_deposite(body: {
    amount: number;
    wallet_address: string;
    transaction_id: string;
  }) {
    return this.http.post(`${environment.apiUrl}user_deposite`, body);
  }
  qr_code_deposite(body:FormData) {
    return this.http.post(`${environment.apiUrl}qr_code`, body);
  }
  qr_code_withdraw(body:FormData) {
    return this.http.post(`${environment.apiUrl}qr_code_withdraw`, body);
  }
  user_withdraw(body: {
    amount: number;
    wallet_address: string;
    transaction_id: string;
    bank_account_name:string;
    name_of_bank:string;
    bank_account_no:string;
    bank_ifsc_code:string;
    branch:string;
  }) {
    return this.http.post(`${environment.apiUrl}user_withdraw`, body);
  }

  qr_code_show() {
    return this.http.get(`${environment.apiUrl}qr_code_show`);
  }
  usdt_show() {
    return this.http.get(`${environment.apiUrl}usdt_show`);
  }
  usdt_add(signature:string,value:number | null) {
    return this.http.get(`${environment.apiUrl}usdt_add?signature=${signature}&value=${value}`);
  }
  qr_code_withdraw_show() {
    return this.http.get(`${environment.apiUrl}qr_code_withdraw_show`);
  }

  user_deposite_list(address: string,page:number) {
    return this.http.get(
      `${environment.apiUrl}user_deposite_list?page=${page}&wallet_address=${address}`
    );
  }
  user_withdraw_list(address: string,page:number) {
    return this.http.get(
      `${environment.apiUrl}user_withdraw_list?page=${page}&wallet_address=${address}`
    );
  }

  admin_deposite_list(signature: string,page:number) {
    return this.http.get(
      `${environment.apiUrl}admin_deposite_list?page=${page}&signature=${signature}`
    );
  }
  admin_deposite_settle_list(signature: string,page:number) {
    return this.http.get(
      `${environment.apiUrl}admin_deposite_settle_list?page=${page}&signature=${signature}`
    );
  }
  admin_withdrawal_settle_list(signature: string,page:number) {
    return this.http.get(
      `${environment.apiUrl}admin_withdraw_settle_list?page=${page}&signature=${signature}`
    );
  }
  admin_withdraw_list(signature: string,page:number) {
    return this.http.get(
      `${environment.apiUrl}admin_withdraw_list?page=${page}&signature=${signature}`
    );
  }

  admin_deposite_status_change(body: {
    signature: string;
    user_deposite_id: number;
    status: number;
    transaction_hash?:string
  }) {
    return this.http.get(
      `${environment.apiUrl}admin_deposite_status_change?signature=${body.signature}&user_deposite_id=${body.user_deposite_id}&status=${body.status}&transaction_hash=${body.transaction_hash}`
    );
  }

  admin_withdraw_status_change(body: {
    signature: string;
    user_withdraw_id: number;
    status: number;
  }) {
    return this.http.get(
      `${environment.apiUrl}admin_withdraw_status_change?signature=${body.signature}&user_withdraw_id=${body.user_withdraw_id}&status=${body.status}`
    );
  }
  admin_deposite_delete(body: { signature: string; user_deposite_id: number }) {
    return this.http.get(
      `${environment.apiUrl}admin_deposite_delete?signature=${body.signature}&user_deposite_id=${body.user_deposite_id}`
    );
  }
  admin_withdrawal_delete(body: { signature: string; user_withdrawal_id: number }) {
    return this.http.get(
      `${environment.apiUrl}admin_deposite_delete?signature=${body.signature}&user_deposite_id=${body.user_withdrawal_id}`
    );
  }

  admin_withdraw_delete(body: { signature: string; user_withdraw_id: number }) {
    return this.http.get(
      `${environment.apiUrl}admin_withdraw_delete?signature=${body.signature}&user_withdraw_id=${body.user_withdraw_id}`
    );
  }
  admin_dashboard() {
    return this.http.get(`${environment.apiUrl}admin_dashboard`);
  }
  userBetList(walletAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer) => {
      this.http
        .get(
          `${environment.apiUrl}getUserResultStatus?walletAddress=${walletAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  header_data(): Observable<any> {
    this.ngxService.start();
    return new Observable((observer) => {
      this.http.get(`${environment.apiUrl}header_data`).subscribe({
        next: (res: any) => {
          observer.next(res);
          this.ngxService.stop();
        },
        error: (err: any) => {
          observer.error(err);
          this.ngxService.stop();
        },
      });
    });
  }

  allGameList() {
    this.ngxService.start();
    return new Observable((observer) => {
      this.http.get(`${environment.apiUrl}getAdminResultStatus`).subscribe({
        next: (res: any) => {
          observer.next(res);
          this.ngxService.stop();
        },
        error: (err: any) => {
          observer.error(err);
          this.ngxService.stop();
        },
      });
    });
  }

  activeGames(walletAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(
          `${environment.apiUrl}check_not_claim?walletAddress=${walletAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  getHistory(walletAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(`${environment.apiUrl}check_claim?walletAddress=${walletAddress}`)
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  getJackpotList(walletAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(
          `${environment.apiUrl}check_claim_jackpot?walletAddress=${walletAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  winnerReportList(reportNo: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(`${environment.apiUrl}winners_report?report_number=${reportNo}`)
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  winnerReportListForLotery(
    reportNo: any,
    walletAddress: string
  ): Observable<any> {
    this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(
          `${environment.apiUrl}user_winners_report?report_number=${reportNo}&walletAddress=${walletAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  getUserEarning(walletAddress: string): Observable<any> {
    this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(`${environment.apiUrl}user_earning?walletAddress=${walletAddress}`)
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  getEarningReport(type: number, walletAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(
          `${environment.apiUrl}earningReports?reportType=${type}&walletAddress=${walletAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  getMatchedNumber(walletAddress: any): Observable<any> {
    this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(
          `${environment.apiUrl}user_winners_count?walletAddress=${walletAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }

  getRank(walletAddress: string) {
    // this.ngxService.start();
    return new Observable((observer: any) => {
      this.http
        .get(
          `${environment.apiUrl}login_user_earning?walletAddress=${walletAddress}`
        )
        .subscribe({
          next: (res: any) => {
            observer.next(res);
            this.ngxService.stop();
          },
          error: (err: any) => {
            observer.error(err);
            this.ngxService.stop();
          },
        });
    });
  }
}
