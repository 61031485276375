<section class="opacityview">
  <section class="main_sec">
    <div class="row justify-content-center">
      <div class="col-12 col-xs-12 col-sm-12 col-md-10 col-xl-10 col-lg-10">
        <div class="row justify-content-center">
          <div class="col-md-10 mb-5">
            <div class="chooseno">
              <div class="row">
                <div class="col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 m-auto">
                  <div>
                    <img [src]="curretQr" alt="" height="250px" width="250px" />
                  </div>
                  <div>
                    <!-- <h6 class="my-2">Powered by Transak</h6> -->
                    <p style="text-transform: initial" class="my-2" >
                      Scan the QR to Send wUSDT to get Fiat 
                    </p>
                    <p class="m-0" >OR</p>
                    <p class="" >Copy Wallet Address : <b> {{ walletAddress }}</b></p>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-xs-12 col-md-8 col-lg-8 col-lx-8">
                  <form [formGroup]="transactionForm" (ngSubmit)="onSubmit()">
                    <div class="row">

                      <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="amount"> wUSDT Amount</label>
                          <input type="number" class="form-control" id="amount" formControlName="amount"
                            (keyup)="calculationOfUsdt()" (ngModelChange)="newAmountFunction($event)" />
                          <div *ngIf="
                              transactionForm.get('amount')?.invalid &&
                              transactionForm.get('amount')?.touched
                            ">
                            <div *ngIf="
                                transactionForm.get('amount')?.errors?.required
                              " class="alert">
                              Amount is required
                            </div>
                            <div *ngIf="
                                transactionForm.get('amount')?.errors?.pattern
                              " class="alert">
                              Amount must be a number
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="bank_account_name">INR</label>
                          <input type="text" class="form-control" id="inr_amount" formControlName="inr_amount"
                            readonly />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="transaction_id">Transaction Hash</label>
                          <input type="text" class="form-control" id="transaction_id"
                            formControlName="transaction_id" />
                          <div *ngIf="
                              transactionForm.get('transaction_id')?.invalid &&
                              transactionForm.get('transaction_id')?.touched
                            ">
                            <div class="alert" *ngIf="
                                transactionForm.get('transaction_id')?.errors
                                  ?.required
                              ">
                              Transaction ID is required
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="wallet_address">User Wallet Address</label>
                          <input type="text" class="form-control" id="wallet_address"
                            formControlName="wallet_address" />
                          <div *ngIf="
                              transactionForm.get('wallet_address')?.invalid &&
                              transactionForm.get('wallet_address')?.touched
                            ">
                            <div class="alert" *ngIf="
                                transactionForm.get('wallet_address')?.errors
                                  ?.required
                              ">
                              Wallet Address is required
                            </div>
                            <div class="alert" *ngIf="f.wallet_address?.hasError('invalidAddress')">
                              Invalid Wallet Address
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="newAmount > 1000">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="form-group">
                            <label for="bank_account_name">Bank Account Name</label>
                            <input type="text" class="form-control" id="bank_account_name"
                              formControlName="bank_account_name"  />
                            <div class="alert" *ngIf="
                                transactionForm.get('bank_account_name')?.errors
                                  ?.required && transactionForm.get('bank_account_name')?.touched
                              ">
                              Bank Account Name Required
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="form-group">
                            <label for="name_of_bank">Name Of Bank</label>
                            <input type="text" class="form-control" id="name_of_bank" formControlName="name_of_bank"
                               />
                            <div class="alert" *ngIf="
                                transactionForm.get('name_of_bank')?.errors
                                  ?.required && transactionForm.get('name_of_bank')?.touched
                              ">
                              Name Of Bank Required
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="form-group">
                            <label for="bank_account_no">Bank Account No</label>
                            <input type="text" class="form-control" id="bank_account_no"
                              formControlName="bank_account_no"  />
                            <div class="alert" *ngIf="
                                transactionForm.get('bank_account_no')?.errors
                                  ?.required && transactionForm.get('bank_account_no')?.touched
                              ">
                              Bank Account No Required
                            </div>
                            <div *ngIf="
                                transactionForm.get('bank_account_no')?.errors
                                  ?.pattern
                              " class="alert">
                              Bank Account No must be a number
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="form-group">
                            <label for="bank_ifsc_code">Bank IFSC Code</label>
                            <input type="text" class="form-control" id="bank_ifsc_code" formControlName="bank_ifsc_code"
                               />
                            <div class="alert" *ngIf="
                                transactionForm.get('bank_ifsc_code')?.errors
                                  ?.required && transactionForm.get('bank_ifsc_code')?.touched
                              "> 
                              Bank IFSC Code Required
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="form-group">
                            <label for="branch">Branch Name</label>
                            <input type="text" class="form-control" id="branch" formControlName="branch"  />
                            <div class="alert" *ngIf="
                                transactionForm.get('branch')?.errors?.required && transactionForm.get('branch')?.touched
                              ">
                              Branch Name Required
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="form-group">
                            <label for="walletAddress">Narration</label>
                            <textarea class="form-control" aria-label="With textarea"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="newAmount <= 1000">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="form-group">
                            <label for="upi_id">UPI Id</label>
                            <input type="text" class="form-control" id="upi_id" formControlName="upi_id"  />
                            <div class="alert" *ngIf="
                                transactionForm.get('upi_id')?.errors?.required && transactionForm.get('upi_id')?.touched
                              "> 
                              UPI Id is Required
                            </div>
                          </div>
                        </div>




                    <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="form-group">
                        <label for="walletAddress">Narration</label>
                        <textarea class="form-control" aria-label="With textarea"></textarea>
                      </div>
                    </div>
                  </div>
                  </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                        <button type="submit" class="btnn" [ngClass]="{'btn read-more-btn1':transactionForm.valid}" [disabled]="transactionForm.invalid">
                          Submit
                        </button>
                      </div>
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-10">
            <app-user-withdrawal-list></app-user-withdrawal-list>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
