// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  LOTTOGame: '0x7E0B77a7B1782e0693D96811BF79063a3015076B',
  lotto_address: '0xD4FB0933B45911D65f55481F98b2C2e20b1Dd56b',
  nft_address: '0x9a13f6E35465f9841D35cce3dE6B19bC64249e29',
  owner_address: '0x17E75Abca0B66Be4942E8A695De92C3b8F6763F6',
  token_address: '0x09B34e72481905A74E463e942Ee8De0Fe52B0203',
  zero_address: '0x0000000000000000000000000000000000000000',
  apiUrl: 'http://128.199.170.196/api/api/',
  exploreUrl: 'https://legacy.wyzthscan.org/',
  rpc_url: 'https://rpc-mainnet.wyzthchain.org/',
  chainId: 303,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
