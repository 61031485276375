<section class="opacityview ">
  <section class="main_sec p-0">

    <div class="row">
      <div class="col-md-12 me-auto ms-auto text-center align-item-center">
        <div class="user_card">

          <table class=" darktable">

            <thead>
              <tr>
                <th>AMOUNT</th>
                <th>WALLET ADDRESS </th>
                <th>TRANSACTION Hash</th>
                <th>STATUS</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of userDeposite ; let i=index;">
                <td>{{item.amount}}</td>
                <td>{{item.wallet_address}}</td>
                <td> <a target="_black" href="{{explorerUrl}}/tx/{{item.transaction_id}}">{{item.transaction_id}}</a>
                </td>
                <td>{{item.status==0 ? 'pending' : item.status==1 ? 'failed' :'Approved' }}</td>
                <td>
                  <button (click)="_viewMoreDetails(item)" class="viewbutton" > view more details</button>
                </td>
              </tr>
              <tr *ngIf="userDeposite.length==0">
                <td colspan="4">No Withdrawal Found</td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="userDeposite.length>0" [(page)]="page" [maxSize]="4" [collectionSize]="totalData"
            (pageChange)="loadPage($event)" class="justify-content-center"></ngb-pagination>
        </div>
      </div>
    </div>

  </section>
</section>
