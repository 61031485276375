import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/core/services/api.service';
import { GlobalService } from 'src/core/services/global.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import Web3 from 'web3';
import { environment } from 'src/environments/environment';

import SwiperCore, {
  FreeMode,
  Pagination,
  Navigation,
  SwiperOptions,
} from 'swiper';
SwiperCore.use([FreeMode, Pagination, Navigation]);
@Component({
  selector: 'app-lottery',
  templateUrl: './lottery.component.html',
  styleUrls: ['./lottery.component.scss'],
})
export class LotteryComponent implements OnInit {

  nftList: any = [];
  selectedNumbers: any[] = [];
  token_id: any;
  rows = [1, 2, 3, 4, 5];
  isShowplaySpinner: boolean = false;

  isClaim: boolean[] = [];
  isClaimJackPot: boolean[] = [];
  activeGamesList: any[] = [];
  isActiveGameLoaded: number = 0;
  isHistoryLoaded: number = 0;
  historyList: any[] = [];
  isHistoryListActive: number = 0;
  JackPotList: any[] = [];
  isJackpotLoaded: number = 0;
  match4winner: any;
  match3winner: any;
  match5winner: any;
  match6Winner: any;
  timestamp: any;
  getBetLastResultNumber: any = [];
  match3WinnerList: any[] = [];
  isLoadingMatch3WinnerList: number = 0;
  @ViewChild('closebuttonreportMatch3') closebuttonreportMatch3: any;
  explorerUrl = environment.exploreUrl;
  WalletAddress: any;
  gameId: any;
  matchedNumbers: any;
  swapperTicketConfig!: SwiperOptions;
  lotteryColumn = 14;
  lotteryRow = 5;



  constructor(private toaster :ToastrService,private apiService: ApiService, private gloabalService: GlobalService) { }

  async ngOnInit() {
    this.gloabalService.getEtherumContractProvider();
    await this.offlineMethod();
    await this.gloabalService.init();
    this.WalletAddress = localStorage.getItem('address');

    this.gloabalService.walletDetails$.subscribe((r) => {
      if (this.gloabalService.isValidAddress(r)) {
        this.WalletAddress = r;
        this.getNftList();
        this.getActiveGame();
        this.getHistory();
        this.getJackPotList();
        this.getMatch4Winner();
      }
    });

    this.getBlockNumber();
    this.swapperTicketConfig = {
      breakpoints: {
        414: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1260: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    };
  }

  isTimeExpired(event: any) {
    if (event == 'expired') {
      setTimeout(() => {
        location.reload();
      }, 10000);
    }
  }
  async offlineMethod() {
    this.gameId = await this.gloabalService.getGameId();
    await this.getBetLastResult();
  }

  getMatch4Winner() {
    this.apiService.getMatchedNumber(this.WalletAddress).subscribe({
      next: (res: any) => {
        this.matchedNumbers = res.data;
        this.match3winner = this.matchedNumbers.filter(
          (x: any) => x.matchedNumber === '3'
        );
        this.match4winner = this.matchedNumbers.filter(
          (x: any) => x.matchedNumber === '4'
        );
        this.match5winner = this.matchedNumbers.filter(
          (x: any) => x.matchedNumber === '5'
        );
        this.match6Winner = this.matchedNumbers.filter(
          (x: any) => x.matchedNumber === '6'
        );
      },
      error: (err: any) => {
        this.matchedNumbers = [];
      },
    });
  }

  async getNftList() {
    this.apiService
      .getNftFromMoralis(this.WalletAddress, this.gameId)
      .subscribe({
        next: (res: any) => {
          this.nftList = res.nftIds;
          this.nftList.forEach((element: any) => {
            element.isChecked = false;
          });
        },
        error: (err: any) => {
          this.nftList = [];
        },
      });
  }

  isSelectedCheckBox(item: any, index: number) {
    this.nftList.forEach((element: any) => {
      if (item.token_id == element.token_id) {
        element.isChecked = !element.isChecked;
      } else {
        element.isChecked = false;
      }
    });
  }

  _selectNumber(item: any) {
    let index = this.selectedNumbers.indexOf(item);
    if (index > -1) {
      this.selectedNumbers.splice(index, 1);
    } else {
      if (this.selectedNumbers.length < 6) {
        this.selectedNumbers.push(item);
      } else {
        this.toaster.error('already max value selected');
        return false;
      }
    }
    return true;
  }

  addRandumNo() {
    this.selectedNumbers = [];
    const nums = new Set();
    while (nums.size !== 6) {
      nums.add(Math.floor(Math.random() * 70) + 1);
    }

    this.selectedNumbers = [...nums];
  }

  async _play() {
    this.token_id = null;

    this.nftList.forEach((element: any) => {
      if (element.isChecked) {
        this.token_id = element.token_id;
      }
    });

    if (this.selectedNumbers.length == 6) {
      if (!this.token_id) {
        this.toaster.error('please select one nft id');
        return;
      }
      try {
        this.isShowplaySpinner = true;
        let hex = await this.gloabalService.play({
          number: this.selectedNumbers,
          nftId: this.token_id,
        });
        await hex.wait(4);
        this.isShowplaySpinner = false;
        this.toaster.success('Bet successfully placed.');
        this.clear();
      } catch (error: any) {
        console.log(error);
        this.isShowplaySpinner = false;
        this.toaster.error(error.error?.data?.message);
      }
    } else {
      this.toaster.error('please select 6 numbers');
      this.isShowplaySpinner = false;
    }
  }

  //function to return list of numbers from 0 to n-1
  numSequence(n: number): Array<number> {
    return Array(n);
  }

  clear() {
    this.nftList.forEach((ele: any) => {
      ele.isChecked = false;
    });
    this.selectedNumbers = [];
  }

  async Claim(gameId: any, index: number, nftId: any) {
    this.isClaim[index] = true;
    try {
      let hex = await this.gloabalService.Claim(gameId, nftId);
      await hex.wait(2);
      this.isClaim[index] = false;
      this.toaster.success('Claime successfully...');
    } catch (e: any) {
      this.isClaim[index] = false;
      console.log(e);
      this.toaster.error(e.error?.data?.message);
    }
  }

  async ClaimJackpot(gameId: any, index: number, nftId: any) {
    this.isClaimJackPot[index] = true;
    try {
      let hex = await this.gloabalService.claimJackpot(gameId, nftId);
      await hex.wait(2);
      this.isClaimJackPot[index] = false;

      this.toaster.success('Claime successfully...');
    } catch (e: any) {
      this.isClaimJackPot[index] = false;
      console.log(e);
      this.toaster.error(e.error?.data?.message);
    }
  }

  refresh() {
    this.getActiveGame();
    this.getHistory();
    this.getJackPotList();
  }

  getActiveGame() {
    this.apiService.activeGames(this.WalletAddress).subscribe({
      next: async (res: any) => {
        console.log('active game=>', res.data);

        this.activeGamesList = res.data;
        this.activeGamesList.forEach((element: any) => {
          element.comman = [];
          if (element.resultNumber) {
            element?.betsNumber.split(',').filter((filterr: string) => {
              if (element.resultNumber.split(',').includes(filterr)) {
                element.comman.push(filterr);
              }
            });
          } else {
            element.comman = 0;
          }
        });

        this.isActiveGameLoaded = 1;
        if (res.data == 0) {
          this.isActiveGameLoaded = 2;
        }
      },
      error: (err: any) => {
        this.isActiveGameLoaded = 2;
      },
    });
  }

  getHistory() {
    this.apiService.getHistory(this.WalletAddress).subscribe({
      next: (res: any) => {
        console.log('history=>', res.data);
        this.historyList = res.data;
        this.historyList.forEach((ele: any) => {
          ele.comman = [];
          if (ele.resultNumber) {
            ele.betsNumber.split(',').filter((filterr: any) => {
              if (ele.resultNumber.split(',').includes(filterr)) {
                ele.comman.push(filterr);
              }
            });
          } else {
            ele.comman = 0;
          }
        });
        this.isHistoryLoaded = 1;
        if (res.data.length == 0) {
          this.isHistoryLoaded = 2;
        }
      },
      error: (err: any) => {
        this.isHistoryLoaded = 2;
      },
    });
  }

  getJackPotList() {
    this.apiService.getJackpotList(this.WalletAddress).subscribe({
      next: (res: any) => {
        console.log('Jackpot =>', res.data);

        this.JackPotList = res.data;
        this.isJackpotLoaded = 1;
        if (res.data.length == 0) {
          this.isJackpotLoaded = 2;
        }
      },
      error: (err: any) => {
        this.isJackpotLoaded = 2;
      },
    });
  }

  async getBlockNumber() {
    var web3Provider = new Web3.providers.HttpProvider(environment.rpc_url);
    var web3 = new Web3(web3Provider);
    let block: any;
    let blockHash: any;
    await web3.eth.getBlockNumber().then((data: any) => {
      block = data;
    });
    await web3.eth.getBlock(block).then((data: any) => {
      blockHash = data.hash;
      this.timestamp = data?.timestamp;
    });
  }

  async getBetLastResult() {
    try {
      let gameId01 =
        await this.gloabalService.lottoGameContract_new.getCurrentGameId();
      this.getBetLastResultNumber =
        await this.gloabalService.lottoGameContract_new.getGameResult(
          gameId01 - 1
        );

      setTimeout(() => {
        this.getBetLastResult();
      }, 30000);
    } catch (error) {}
  }

  cloreModalReportMatch3() {
    this.closebuttonreportMatch3.nativeElement.click();
  }

  getMatch3WinnerReport(numberReport: number) {
    this.match3WinnerList = [];
    this.isLoadingMatch3WinnerList = 0;
    this.apiService
      .winnerReportListForLotery(numberReport, this.WalletAddress)
      .subscribe({
        next: (res: any) => {
          this.isLoadingMatch3WinnerList = 1;
          this.match3WinnerList = res.data;
          if (res.data.length == 0) {
            this.isLoadingMatch3WinnerList = 2;
          }
        },
        error: (err: any) => {
          this.isLoadingMatch3WinnerList = 2;
        },
      });
  }
}
