import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { BetComponent } from './bet/bet.component';
import { BuyTokenComponent } from './buy-token/buy-token.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { LotteryComponent } from './lottery/lottery.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserDepositeListComponent } from './user-deposite-list/user-deposite-list.component';
import { UserWithdrawalListComponent } from './user-withdrawal-list/user-withdrawal-list.component';
import { WithdrawalTokenComponent } from './withdrawal-token/withdrawal-token.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'backoffice', component: BackofficeComponent },
  { path: 'bet', component: BetComponent },
  { path: 'lottery', component: LotteryComponent },
  { path: 'buy-token', component: BuyTokenComponent },
  // { path: 'user-deposite-list', component: UserDepositeListComponent },
  // { path: 'user-withdrawal-list', component: UserWithdrawalListComponent },
  { path: 'withdrawal-token', component: WithdrawalTokenComponent },
  { path: 'admin' , loadChildren:()=>import('./admin/admin.module').then(m=>m.AdminModule)},
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
