<section class="opacityview">
  <section class="people_sec" style="padding: 7rem 0 0">
    <div class="container">
      <div class="row">
        <div class="col-md-3 mb-3">
          <a href="">
            <img src="assets/img/image/back.png" alt="" />
          </a>
        </div>
      </div>
      <div class="featured-auction-bg">
        <div class="row align-items-center">
          <div class="col-md-4 text-center align-item-center">
            <a [routerLink]="['/lottery']">
              <div class="start-sec">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h2>DRAW STARTS IN</h2>
                    <div class="counter">
                      <div class="digital">
                        <app-count-down-timer
                          (timexpired)="isTimeExpired($event)"
                          [cssLotteryPosition]="1"
                        ></app-count-down-timer>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-xl-12">
                        <h2>LAST DRAW</h2>
                        <div class="series" style="margin: 15px 0">
                          <ng-container
                            *ngIf="getBetLastResultNumber?.status == 10"
                          >
                            <ng-container
                              *ngFor="
                                let item of getBetLastResultNumber?.randomNos
                              "
                            >
                              <div class="circle headings">
                                {{ item ?? "-" }}
                              </div>
                            </ng-container>
                          </ng-container>

                          <ng-container
                            *ngIf="getBetLastResultNumber?.status != 10"
                          >
                            <div class="circle headings">--</div>
                            <div class="circle headings">--</div>
                            <div class="circle headings">--</div>
                            <div class="circle headings">--</div>
                            <div class="circle headings">--</div>
                            <div class="circle headings">--</div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mobile-view"
                  >
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-xl-12">
                        <h2>NEXT GAME ID :</h2>
                        <div class="series gamepad">
                          <h1 class="gameid">
                            {{ gameId }}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-8">
            <div
              class="ticketpart me-auto ms-auto align-item-center"
              *ngIf="WalletAddress"
            >
              <p class="lottery-heading text-left">Select Ticket</p>

              <swiper
                [slidesPerView]="5"
                [config]="swapperTicketConfig"
                [spaceBetween]="30"
                [freeMode]="true"
                [navigation]="true"
                style="height: 10pc; width: 95%; z-index: 0"
                class="mySwiper"
              >
                <ng-template
                  swiperSlide
                  *ngFor="let item of nftList; let i = index"
                >
                  <div>
                    <input
                      type="checkbox"
                      value="{{ item.token_id }}"
                      id="myCheckbox{{ item.token_id }}"
                      [checked]="item.isChecked"
                      [disabled]="item.status"
                      (click)="isSelectedCheckBox(item, i)"
                    />
                    <label class="card p-2" for="myCheckbox{{ item.token_id }}">
                      <img
                        _ngcontent-mhc-c56=""
                        src="assets/img/image/Ticket.png"
                        class="ticketgif"
                        style="width: 100%; border-radius: 10px; height: 87px"
                      />
                      <p class="mb-0 tokenid">{{ item.token_id }}</p>
                    </label>
                  </div>
                </ng-template>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="lottery-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12 me-auto ms-auto text-center align-item-center">
          <div class="Lotterypart">
            <div class="row">
              <div class="col-md-12 col-xs-12 col-xs-12 col-12">
                <div
                  class="ticketpart me-auto ms-auto align-item-center"
                  *ngIf="!WalletAddress"
                >
                  <p class="lottery-heading text-left">Please connect wallet</p>
                </div>
              </div>
              <div
                class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <p class="lottery-heading mb-5">PICK YOUR LUCKY BETS</p>
                <div class="chooseno">
                  <div
                    class="numbercheckbox"
                    *ngFor="let n of numSequence(lotteryRow); let j = index"
                  >
                    <label
                      class="numberlabel"
                      *ngFor="
                        let m of numSequence(lotteryColumn);
                        let i = index
                      "
                    >
                      <input
                        type="checkbox"
                        [checked]="
                          selectedNumbers.indexOf(i + 1 + lotteryColumn * j) >
                          -1
                            ? true
                            : false
                        "
                        (click)="_selectNumber(i + 1 + lotteryColumn * j)"
                      />
                      <span class="checkmarkno">{{
                        i + 1 + lotteryColumn * j
                      }}</span>
                    </label>
                  </div>
                </div>
                <div class="row lotteryButtons">


                  <div class="col-md-2 col-4">
                    <a
                      href="javascript:void(0)"
                      class="read-more-btn d-inline-block"
                      (click)="addRandumNo()"
                      >Random</a
                    >
                  </div>
                  <div class="col-md-2 col-4">
                    <a
                      href="javascript:void(0)"
                      (click)="clear()"
                      class="read-more-btn1 d-inline-block"
                      >Clear</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                style="margin: auto 0 auto auto"
              >
                <div class="numberpart">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-3    my-auto">
                      <div class="row justify-content-center">
                        <div class="col-md-12 col-sm-12 m-auto">
                          <h3
                            style="
                              font-size: 18px;
                             

                              margin: 0;
                             
                              margin: 5%;
                            "
                          >
                            DRAW WILL START IN
                          </h3>
                        </div>
                        <div class="col-md-12 col-sm-12">
                          <div class="digital">
                            <app-count-down-timer
                              [cssLotteryPosition]="2"
                            >
                            </app-count-down-timer>
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                    <div class=" col-12 col-sm-12 col-md-7 my-auto">
                      <p class="lottery-heading">My Numbers</p>
                      <div class="numberscheck justify-content-center">
                        <p
                          *ngFor="let item of selectedNumbers; let i = index"
                          class="numbersnft"
                        >
                          {{ item }}<span *ngIf="i < 5"></span>
                        </p>
                      </div>
                    </div>
                    <div class=" col-12 col-sm-12 col-md-2  my-auto">
                      <a
                        type="button"
                        (click)="_play()"
                        class="read-more-btn d-inline-block"
                      >
                        Play
                        <i
                          *ngIf="isShowplaySpinner"
                          class="fa fa-spinner fa-spin"
                          style="font-size: 16px"
                        ></i>
                      </a>
                    </div>
                  </div>

                </div>

                <div class="goodluck mt-5">
                  <h4 class="lottery-heading">GOOD LUCK !</h4>
                </div>
                <div class="row">
                  <div class="col-md-12">

                      <div
                        [routerLink]="['/home']"
                        [queryParams]="{ buyFirstNft: true }"
                      >
                        <p
                          class="btn buyBtn text-white mt-3 mb-0"
                          style="justify-content: center"
                        >
                          To play a game need to buy atleast one NFT if you dont have
                          any click here buy NFT
                          <span>
                            <i class="fa-solid fa-cart-shopping m-auto"></i> BUY NFT
                            FIRST <i class="fa-brands fa-searchengin m-auto"></i>
                          </span>
                        </p>
                      </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="row mb-5">
            <div
              class="col-md-10 me-auto ms-auto text-center align-item-center"
            >
              <h3 class="mb-5 mt-5">MY HITS</h3>
              <div class="row">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 reportcolumn"
                >
                  <div
                    class="card reportcard"
                    data-bs-toggle="modal"
                    (click)="getMatch3WinnerReport(3)"
                    data-bs-target="#reportMatch3"
                  >
                    <h2>
                      {{ (match3winner===undefined || match3winner?.length==0) ? 0 : match3winner?.[0].count }}
                    </h2>
                    <h5>3 HITS</h5>
                    <a
                      data-bs-toggle="modal"
                      (click)="getMatch3WinnerReport(3)"
                      data-bs-target="#reportMatch3"
                      >REPORTS</a
                    >
                  </div>
                </div>
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 reportcolumn"
                >
                  <div
                    class="card reportcard"
                    data-bs-toggle="modal"
                    (click)="getMatch3WinnerReport(4)"
                    data-bs-target="#reportMatch3"
                  >
                    <h2>
                      {{ (match4winner===undefined || match4winner?.length==0) ? 0 : match4winner?.[0].count }}
                    </h2>
                    <h5>4 HITS</h5>
                    <!-- <a data-bs-toggle="modal" data-bs-target="#reportMatch4" class="btn btn-success">Reports</a> -->
                    <a
                      data-bs-toggle="modal"
                      (click)="getMatch3WinnerReport(4)"
                      data-bs-target="#reportMatch3"
                      >REPORTS</a
                    >
                  </div>
                </div>

                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 reportcolumn"
                >
                  <div
                    class="card reportcard"
                    data-bs-toggle="modal"
                    (click)="getMatch3WinnerReport(5)"
                    data-bs-target="#reportMatch3"
                  >
                    <h2>
                      {{ (match5winner===undefined || match5winner?.length==0) ? 0 : match5winner?.[0].count }}
                    </h2>
                    <h5>5 HITS</h5>
                    <!-- <a data-bs-toggle="modal" data-bs-target="#reportMatch5" class="btn btn-success">Reports</a> -->
                    <a
                      data-bs-toggle="modal"
                      (click)="getMatch3WinnerReport(5)"
                      data-bs-target="#reportMatch3"
                      >REPORTS</a
                    >
                  </div>
                </div>
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 reportcolumn"
                >
                  <div
                    class="card reportcard"
                    data-bs-toggle="modal"
                    (click)="getMatch3WinnerReport(6)"
                    data-bs-target="#reportMatch3"
                  >
                    <h2>
                      {{(match6Winner===undefined || match6Winner?.length==0) ? 0 : match6Winner?.[0].count }}
                    </h2>
                    <h5>6 HITS</h5>
                    <!-- <a data-bs-toggle="modal" data-bs-target="#reportMatch6" class="btn btn-success">Reports</a> -->
                    <a
                      data-bs-toggle="modal"
                      (click)="getMatch3WinnerReport(6)"
                      data-bs-target="#reportMatch3"
                      >REPORTS</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section>
            <div class="user_card pos">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <span
                    class="active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#activeGame"
                    type="button"
                    role="tab"
                    aria-controls="activeGame"
                    aria-selected="true"
                    >Active Games</span
                  >
                </li>

                <li class="nav-item" role="presentation">
                  <span
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#jackpot"
                    type="button"
                    role="tab"
                    aria-controls="jackpot"
                    aria-selected="false"
                    >Jackpot</span
                  >
                </li>

                <li class="nav-item" role="presentation">
                  <span
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#history"
                    type="button"
                    role="tab"
                    aria-controls="history"
                    aria-selected="false"
                    >History</span
                  >
                </li>
              </ul>
              <a
                (click)="refresh()"
                style="position: absolute; 
                right: 3px;
                top: 0px;
            "
              >
                <div class="text-right02">
                  <i class="fa-solid fa-arrows-rotate" style="    font-size: 10px;" ></i>
                </div>
              </a>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="activeGame"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabindex="0"
                >
                  <div class="container">
                    <div class="row">
                      <div
                        class="col-md-12 me-auto ms-auto text-center align-item-center"
                      >
                        <div class="darktable">
                          <table class="table table-borderless">
                            <thead  style="border-bottom: 1px solid #013e76;" >
                              <tr>
                                <th class="tabhead">NftId</th>
                                <th class="tabhead">Bets Number</th>
                                <th class="tabhead">Result Numbers</th>
                                <th class="tabhead">Matched Numbers</th>
                                <th class="tabhead">Matched Count</th>
                                <th class="tabhead">Game Id</th>
                                <th class="tabhead">Result</th>
                                <th class="tabhead">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngIf="isActiveGameLoaded == 1">
                                <tr
                                  *ngFor="
                                    let item of activeGamesList;
                                    let i = index
                                  "
                                >
                                  <td>{{ item.nftId }}</td>
                                  <td>{{ item.betsNumber }}</td>
                                  <td>
                                    <span *ngIf="item.resultNumber">{{
                                      item.resultNumber
                                    }}</span>
                                    <span *ngIf="!item.resultNumber">--</span>
                                  </td>
                                  <td>{{ item.comman }}</td>
                                  <td>{{ item.comman.length }}</td>

                                  <td>{{ item.gameId }}</td>
                                  <td>
                                    <app-coundown-timer-two
                                      *ngIf="item.gameResultTime && timestamp"
                                      [gameResultTime]="item.gameResultTime"
                                      [FromTab]="'activeGame'"
                                    ></app-coundown-timer-two>
                                    <p *ngIf="!item.gameResultTime">
                                      Not Declared...
                                    </p>
                                  </td>
                                  <td>
                                    <a
                                      class="claim-button"
                                      (click)="
                                        Claim(item.gameId, i, item.nftId)
                                      "
                                    >
                                      Claim
                                      <i
                                        *ngIf="isClaim[i]"
                                        class="fa fa-spinner fa-spin"
                                        style="font-size: 25px"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </ng-container>

                              <tr *ngIf="isActiveGameLoaded == 0">
                                <td colspan="8">Loading...</td>
                              </tr>
                              <tr *ngIf="isActiveGameLoaded == 2">
                                <td colspan="8">Not Found...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="jackpot"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                  tabindex="0"
                >
                  <div class="container">
                    <div class="row">
                      <div
                        class="col-md-12 me-auto ms-auto text-center align-item-center"
                      >
                        <div class="darktable">
                          <table class="table table-borderless">
                            <thead style="border-bottom: 1px solid #013e76;">
                              <tr>
                                <th class="tabhead">NftId</th>
                                <th class="tabhead">Game Id</th>
                                <!-- <th  class="tabhead">Win Price</th> -->
                                <th class="tabhead">Bet Number</th>
                                <th class="tabhead">Result</th>
                                <th class="tabhead">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngIf="isJackpotLoaded == 1">
                                <tr
                                  *ngFor="
                                    let item of JackPotList;
                                    let i = index
                                  "
                                >
                                  <td>{{ item.nftId }}</td>
                                  <td>{{ item.gameId }}</td>
                                  <!-- <td>{{ item.winPrice }}</td> -->
                                  <td>{{ item.matchedNumber }}</td>
                                  <td>
                                    <app-coundown-timer-two
                                      *ngIf="item.gameResultTime && timestamp"
                                      [gameResultTime]="item.gameResultTime"
                                      [FromTab]="'jackpot'"
                                    ></app-coundown-timer-two>
                                    <p *ngIf="!item.gameResultTime">
                                      Not Declared...
                                    </p>
                                  </td>
                                  <td>
                                    <a
                                      class="btn btn-play1"
                                      (click)="
                                        ClaimJackpot(item.gameId, i, item.nftId)
                                      "
                                    >
                                      Claim
                                      <i
                                        *ngIf="isClaimJackPot[i]"
                                        class="fa fa-spinner fa-spin"
                                        style="font-size: 25px"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </ng-container>

                              <tr *ngIf="isJackpotLoaded == 0">
                                <td colspan="6">Loading...</td>
                              </tr>
                              <tr *ngIf="isJackpotLoaded == 2">
                                <td colspan="6">Not Found...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="history"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabindex="0"
                >
                  <div class="container">
                    <div class="row">
                      <div
                        class="col-md-12 me-auto ms-auto text-center align-item-center"
                      >
                        <div class="darktable">
                          <table class="table table-borderless">
                            <thead style="border-bottom: 1px solid #013e76;">
                              <tr>
                                <th class="tabhead">NftId</th>
                                <th class="tabhead">Game Id</th>
                                <th class="tabhead">Win Price</th>
                                <th class="tabhead">Bet Number</th>
                                <th class="tabhead">Result Number</th>
                                <th class="tabhead">Match Number</th>
                                <th class="tabhead">Match Number count</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngIf="isHistoryLoaded == 1">
                                <tr
                                  *ngFor="
                                    let item of historyList;
                                    let i = index
                                  "
                                >
                                  <td>{{ item.nftId }}</td>
                                  <td>{{ item.gameId }}</td>
                                  <td>{{ item.winPrice / 1e6 }}</td>
                                  <td>{{ item.betsNumber }}</td>
                                  <td>{{ item.resultNumber }}</td>
                                  <td>{{ item.comman }}</td>
                                  <td>{{ item.comman.length }}</td>
                                </tr>
                              </ng-container>

                              <tr *ngIf="isHistoryLoaded == 0">
                                <td colspan="7">Loading...</td>
                              </tr>
                              <tr *ngIf="isHistoryLoaded == 2">
                                <td colspan="7">Not Found...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-left text-warning">
                *Note : Data will be reflected after 2 minutes of Transaction
                confirmation.
              </p>
            </div>
          </section>

          <section>
            <div
              class="modal fade"
              id="reportMatch3"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
              >
                <div class="modal-content">
                  <div class="text-end">
                    <button
                      type="button"
                      #DirectSale
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      class="closebutton"
                    >
                      <img src="assets/img/image/close.png" alt="" />
                    </button>
                  </div>
                  <div class="modal-body">
                    <table class="table">
                      <thead style="border-bottom: 1px solid #013e76;">
                        <tr>
                          <th scope="col" class="">Sr.No</th>
                          <th scope="col" class="">Users</th>
                          <th scope="col" class="">Earning</th>
                          <th scope="col" class="">Number</th>
                          <th scope="col" class="">Hash</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="isLoadingMatch3WinnerList == 1">
                          <tr
                            *ngFor="let item of match3WinnerList; let i = index"
                          >
                            <th scope="row" class="">{{ i + 1 }}</th>
                            <td class="">
                              {{ item.walletAddress.slice(0, 5) }}...{{
                                item.walletAddress.slice(-5)
                              }}
                            </td>
                            <td class="">{{ item.reward / 1e6 }}</td>
                            <td class="">{{ item.betsNumber }}</td>
                            <td class="">
                              <a
                                href="{{ explorerUrl }}tx/{{
                                  item.transactionHash
                                }}"
                                class=""
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {{ item.transactionHash.substring(0, 4) }}...{{
                                  item.transactionHash.substring(60)
                                }}
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="isLoadingMatch3WinnerList == 0">
                          <td colspan="5" class="" style="border: none">
                            Loading....
                          </td>
                        </tr>
                        <tr *ngIf="isLoadingMatch3WinnerList == 2">
                          <td colspan="5" class="" style="border: none">
                            Not Found
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</section>
