<div style="position: relative;" >
  <span (click)="dialogRef.close()" class="closebutton" >X</span>
    <h5 class="text-center" ><b>More Details</b></h5>
    <div class="moreamount">
      <p>Amount : </p>
      <p><b>{{data.amount}}</b></p>
    </div>
    <div class="moreamount">
      <p>Bank Account Name : </p>
      <p><b>{{data.bank_account_name}}</b></p>
    </div>
    <div class="moreamount">
      <p>Account Number : </p>
      <p><b>{{data.bank_account_no}}</b></p>
    </div>
    <div class="moreamount">
      <p>IFSC Code : </p>
      <p><b>{{data.bank_ifsc_code}}</b></p>
    </div>
    <div class="moreamount">
      <p>Bank Name : </p>
      <p><b>{{data.name_of_bank}}</b></p>
    </div>
    <div class="moreamount">
      <p>Status : </p>
      <p><b>{{data.status==0 ? 'pending' : data.status==1 ? 'failed' :'Approved'}}</b></p>
    </div>
    <div class="moreamount">
      <p>UPI ID : </p>
      <p><b>{{data.upi_id}}</b></p>
    </div>
    <div class="moreamount">
      <p>Wallet Address : </p>
      <p class="text-truncate" ><b>{{data.wallet_address}}</b></p>
    </div>
    <div class="moreamount">
      <p>Transaction Hash : </p>
      <p class="text-truncate" ><b>{{data.transaction_id}}</b></p>
    </div>
</div>
