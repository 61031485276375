
<app-header *ngIf="!isAdmin"></app-header>
<router-outlet>
 
</router-outlet>
<app-footer></app-footer>

<!-- <ngx-ui-loader></ngx-ui-loader> -->
<ngx-ui-loader [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>
<ng-template #foregroundSpinner >
    <!-- Your awesome foreground spinner defined here -->
    <div>
        <img src="assets/img/image/loader-2-bg.gif" alt="" class="cssload-wrap" >
    </div>
  </ng-template>
  
  <!-- <ng-template #backgroundSpinner>
  </ng-template> -->