import { MatDialogModule } from '@angular/material/dialog';
import { NgModule ,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { BetComponent } from './bet/bet.component';
import { LotteryComponent } from './lottery/lottery.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { CountDownTimerComponent } from './count-down-timer/count-down-timer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CoundownTimerTwoComponent } from './coundown-timer-two/coundown-timer-two.component';
import { DateFormatPipe } from './home/date-format.pipe';
import { SwiperModule } from 'swiper/angular';
import { AnimatedDigitComponent } from './animated-digit/animated-digit.component';
import { BuyTokenComponent } from './buy-token/buy-token.component';
import { UserDepositeListComponent } from './user-deposite-list/user-deposite-list.component';
import { WithdrawalTokenComponent } from './withdrawal-token/withdrawal-token.component';

import { MatMenuModule } from "@angular/material/menu";
import { UserWithdrawalListComponent } from './user-withdrawal-list/user-withdrawal-list.component';
import { ApiInterceptor } from 'src/core/interceptor/api.interceptor';
import { ViewMoreDetailsComponent } from './withdrawal-token/view-more-details/view-more-details.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BackofficeComponent,
    BetComponent,
    LotteryComponent,
    CountDownTimerComponent,
    PageNotFoundComponent,
    CoundownTimerTwoComponent,
    DateFormatPipe,
    AnimatedDigitComponent,
    BuyTokenComponent,
    UserDepositeListComponent,
    WithdrawalTokenComponent,
    UserWithdrawalListComponent,
    ViewMoreDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    SwiperModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatDialogModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})

export class AppModule { }
