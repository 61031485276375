import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ethers } from 'ethers';
import { Observable, BehaviorSubject ,Subject } from 'rxjs';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { environment } from 'src/environments/environment';
import { CHAIN_CONFIGS } from '../../assets/chainConfig/blockchain.configs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
// const baseURL: any = "http://localhost:59092/";
const baseURL: any = environment.apiUrl;
function _window(): any {
  return window;
}
export interface AddEthereumChainParameter {
  chainId: string;
  blockExplorerUrls?: string[];
  chainName?: string;
  iconUrls?: string[];
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls?: string[];
}

declare let require: any;
declare let window: any;
const tokenAbi = require('./../../assets/token.json');
const contarctAbi = require('./../../assets/lotto.json');
const lottoGameAbi = require('./../../assets/LottoGameAbi.json');
const nftAbi = require('./../../assets/nftAbi.json');
const providerOptions = {
  rpc: {
    303: environment.rpc_url,
  },
  network: 'wyzth',
  chainId: environment.chainId,
};

interface apiCallI {
  isCallApi:boolean;
}



const provider = new WalletConnectProvider(providerOptions);

@Injectable({
  providedIn: 'root',
})
export class GlobalService implements OnInit {
  public _web3: any;
  confirmation$ = new BehaviorSubject({
    isInitiate: false,
    isTransaction: false,
    isSubmitting: false,
  });
  _account: any;
  counter = 1;

  provider: any;
  signer: any;
  ReferalAddress: any;
  public walletDetails$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  contract: any;
  lottoGameContract: any;
  tokenContract: any;
  chainConfigs = CHAIN_CONFIGS;
  changNetwork$ = new BehaviorSubject({});
  url_id: any;
  contract_new: any;
  lottoGameContract_new: any;
  tokenContract_new: any;
  userDepositeApiCall$ = new Subject<apiCallI>();
  userWithdrawalApiCall$ = new Subject<apiCallI>();


  constructor(public http: HttpClient, private toaster: ToastrService) {}
  ngOnInit(): void {
    // this.changNetwork$.subscribe({
    //   next: (res: any) => {
    //     if (res.isWrongNetwork) {
    //       this.toaster.error('wrong network');
    //       this.switchNetwork(environment.chainId);
    //     }
    //   },
    // });
  }

  getEtherumContractProvider() {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        environment.rpc_url
      );
      this.contract_new = new ethers.Contract(
        environment.lotto_address,
        contarctAbi,
        provider
      );
      this.lottoGameContract_new = new ethers.Contract(
        environment.LOTTOGame,
        lottoGameAbi,
        provider
      );
      this.tokenContract_new = new ethers.Contract(
        environment.token_address,
        tokenAbi,
        provider
      );
    } catch (error) {}
  }

   async checkTransactionHash(txHash: string) {
    try {
      // call the provider object's getTransaction function with the txHash
      const tx = await provider.getTransaction(txHash);
      // if the tx object is not null and has a blockNumber property, it's a valid transaction
      if (tx && tx.blockNumber) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(`Error: ${err}`);
      return false;
    }
  }

  getWalletObs(): Observable<any> {
    return this.walletDetails$.asObservable();
  }

  setWalletObs(profile: any) {
    this.walletDetails$.next(profile);
  }

  async init(): Promise<void> {
    let isConnected = localStorage.getItem('wallet') == '1';
    if (isConnected) {
      if ((localStorage.getItem('wallet') ?? '1') == '1') {
        await this.connectContract();
      } else if ((localStorage.getItem('wallet') ?? '1') == '2') {
        await this.connectAccountWalletConnect();
      }
    }
  }

  async connectContract() {
    if (
      typeof this.nativeWindow.ethereum !== undefined &&
      typeof this.nativeWindow.ethereum != undefined &&
      typeof this.nativeWindow.ethereum != 'undefined'
    ) {
      await this.nativeWindow.ethereum.enable();
      this.provider = new ethers.providers.Web3Provider(
        this.nativeWindow.ethereum
      );

      await this.getAccountAddress();
      localStorage.setItem('wallet', '1');
      this.nativeWindow.ethereum.on('accountsChanged', (accounts: string[]) => {
        this.connectContract();
        location.reload();
      });

      this.nativeWindow.ethereum.on('networkChanged', (reason: string) => {
        if (reason != environment.chainId.toString()) {
          this.switchNetwork(environment.chainId);
          this.toaster.error('you are on wrong network');
          // this.changNetwork$.next({isWrongNetwork:true})
        }

        // location.reload();
      });
    }
  }

  async connectAccountWalletConnect() {
    await provider
      .enable()
      .then(() => console.log('first call resolved'))
      .catch(() => provider.disconnect());

    this.provider = new ethers.providers.Web3Provider(provider);
    await this.getAccountAddress();
    localStorage.setItem('wallet', '2');

    provider['on']('accountsChanged', async (accounts: string[]) => {
      location.reload();
    });

    // Subscribe to session disconnection
    provider['on']('disconnect', (code: number, reason: string) => {
      location.reload();
    });

    // Subscribe to session disconnection
    provider['on']('networkChanged', (code: number, reason: string) => {
      this.connectAccountWalletConnect();
    });
  }

  async getAccountAddress() {
    this.signer = this.provider.getSigner();
    this._account = await this.signer.getAddress();
    var network = await this.provider.getNetwork();
    localStorage.setItem('chainId', network.chainId);
    if (network.chainId != environment.chainId) {
      await this.addNetwork();
      this.switchNetwork(environment.chainId);
    }
    localStorage.setItem('address', this._account);
    if (network.chainId == environment.chainId) {
      this.contract = new ethers.Contract(
        environment.lotto_address,
        contarctAbi,
        this.signer
      );
      this.lottoGameContract = new ethers.Contract(
        environment.LOTTOGame,
        lottoGameAbi,
        this.signer
      );
      this.tokenContract = new ethers.Contract(
        environment.token_address,
        tokenAbi,
        this.signer
      );
    }
    this.setWalletObs(this._account);
  }

  disconnectMetamaskWallet() {
    localStorage.clear();
    this.setWalletObs(null);
    location.reload();
  }

  async transferToken(address: string, amount:any) {
    return await this.tokenContract.transfer(address,amount);
  }

  async switchNetwork(chainId: number | string) {
    let switchNetwork = await new Promise(async (resolve, reject) => {
      let wallet: any = localStorage.getItem('wallet');
      if (parseInt(wallet) == 1) {
        try {
          await this.nativeWindow.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }], // chainId must be in hexadecimal numbers
          });
          resolve('switched Network successfully');
        } catch (error: any) {
          console.log('Switch Wallet==>', error);
        }
      }
    });

    return switchNetwork;
  }

  async signMessage(message: any) {
    var signature = await this.signer.signMessage(message);
    return { status: true, signature };
  }
  async addNetwork() {
    await this.nativeWindow.ethereum.request(this.chainConfigs['303'].config);
  }

  get nativeWindow(): any {
    return _window();
  }

  async nftBuyAmount() {
    return this.contract.nftBuyAmount();
  }
  async secondNftBuyAmount() {
    return this.contract.secondNftBuyAmount();
  }

  async getAccount() {
    return this._account;
  }

  async play(item: any) {
    return await this.lottoGameContract.play(item.number, item.nftId);
  }

  async gameSpan() {
    return await this.lottoGameContract_new.gameSpan();
  }
  async isAllowanceApproved(_allowanceAmount: any) {
    try {
      let tx = await this.contract.allowance(
        this._account,
        environment.lotto_address
      );
      console.log(tx._hex);

      if (tx >= _allowanceAmount) {
        return { status: true };
      } else {
        return { status: false };
      }
    } catch (e) {
      console.log(e);
      return { status: false };
    }
  }

  public async approveToken(_getAmount: any) {
    try {
      let tx = await this.tokenContract.approve(
        this._account,
        _getAmount + '000000000000000000'
      );
      return { hash: tx, status: true, allowance: false };
    } catch (e) {
      console.log(e);
      return { hash: e, status: false };
    }
  }

  async checkAllowance(address: any) {
    this.tokenContract = new ethers.Contract(
      environment.token_address,
      tokenAbi,
      this.signer
    );
    return await this.tokenContract.allowance(
      address,
      environment.lotto_address
    );
  }
  async approve(nftPrice: any) {
    this.tokenContract = new ethers.Contract(
      environment.token_address,
      tokenAbi,
      this.signer
    );
    return await this.tokenContract.approve(
      environment.lotto_address,
      nftPrice
    );
  }
  async checkAllowancetest(address: any) {
    this.tokenContract = new ethers.Contract(
      environment.token_address,
      tokenAbi,
      this.signer
    );
    return await this.tokenContract.allowance(address, address);
  }
  async approvetest(nftPrice: any, address: string) {
    this.tokenContract = new ethers.Contract(
      environment.token_address,
      tokenAbi,
      this.signer
    );
    return await this.tokenContract.approve(address, nftPrice);
  }

  async buyNft(_referralAddress: any, _amount: any) {
    try {
      return await this.contract.buyFirstNft(_referralAddress, {
        value: _amount,
      });
    } catch (e) {
      console.log(e);
      return { hash: '', status: false };
    }
  }

  async Claim(gameId: any, nftId: any) {
    return await this.lottoGameContract.settleBet(gameId, nftId);
  }

  async claimJackpot(gameId: any, nftId: any) {
    return await this.lottoGameContract.claimJackpot(gameId, nftId);
  }

  async buyFirstNft(item: string) {
    return await this.contract.buyFirstNft(item);
  }

  async buySecondNft() {
    return await this.contract.buySecondNft();
  }
  async getPlayerDetails(address: string) {
    return await this.contract.player(address);
  }
  async getPlayerDetails01(address: string) {
    return await this.lottoGameContract.getPlayerDetails(address);
  }
  async globalAmount() {
    return await this.contract.globalAmount();
  }

  async adminGetResult(gameId: any) {
    return await this.lottoGameContract.getResult(gameId);
  }

  async getRoundId() {
    return await this.contract_new.accumulatRoundID();
  }

  async getRoundIdOffline() {
    return await this.contract_new.accumulatRoundID();
  }

  async balanceOf(address: string) {
    return await this.tokenContract_new.balanceOf(address);
  }

  async getHighestRef(roundId: any) {
    return await this.contract_new.getHighestReferrer(roundId);
  }

  async getGameStartTime() {
    return await this.lottoGameContract_new.gameStartTime();
  }

  async getWinnerCount(number: any) {
    return await this.lottoGameContract.winnerCount(number);
  }

  async getWinnerCountOffline(number: any) {
    return await this.lottoGameContract_new.winnerCount(number);
  }

  public isValidAddress(address: any) {
    return ethers.utils.isAddress(address);
  }

  async getCurrentRefferalInfo(address: any) {
    return await this.contract.referralEarningInfo(address);
  }

  async getAvailableTimeToClaimJackpot() {
    return await this.lottoGameContract.availableTimeToClaimJackpot();
  }

  async getAvailableTimeToClaim() {
    return await this.lottoGameContract.availableTimeToClaim();
  }

  getNFT(): Observable<any> {
    // return this.httpClient.get(baseURL + 'Customer/GetApplication/');
    // console.log(baseURL + 'sellNFTOwnerList');
    return this.http.get(baseURL + 'play_event_list');
  }

  getReferalEarningInfo(address: string) {
    return this.contract.referralEarningInfo(address);
  }

  getUplineid(route: ActivatedRoute) {
    let found = false;
    route.queryParams.subscribe((params) => {
      if (params['referalAddress'] !== undefined) {
        this.url_id = params['referalAddress'];
        found = true;
      }
    });

    if (
      localStorage.getItem('upline') != null &&
      localStorage.getItem('upline') !== 'undefined' &&
      !found
    ) {
      this.url_id = localStorage.getItem('upline');
    } else if (found) {
      localStorage.setItem('upline', this.url_id);
    } else if (
      !found &&
      (localStorage.getItem('upline') === null ||
        localStorage.getItem('upline') === 'undefined')
    ) {
      this.url_id = environment.owner_address;
    }

    return this.url_id;
  }

  async getGameId() {
    return await this.lottoGameContract_new.getCurrentGameId();
  }
}
