import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/core/services/api.service';
import { GlobalService } from 'src/core/services/global.service';

// export function validateTransaction(): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: any } | null =>
//     control.value == ''
//       ? null
//       : this.cs.checkTransactionHash(control.value)
//       ? null
//       : { invalidAddress: true };
// }
export function validateWalletAddress(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value == ''
      ? null
      : ethers.utils.isAddress(control.value)
      ? null
      : { invalidAddress: true };
}
export function checkAmount(amount:number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value == ''
      ? null
      : amount>1000
      ? null
      : { require: true };
}

@Component({
  selector: 'app-withdrawal-token',
  templateUrl: './withdrawal-token.component.html',
  styleUrls: ['./withdrawal-token.component.scss'],
})
export class WithdrawalTokenComponent implements OnInit {
  transactionForm!: FormGroup;
  curretQr: any;
  newAmount: number=0;
  usdtPrice  = 0;
  walletAddress: any;

  constructor(
    private fb: FormBuilder,
    private api_service: ApiService,
    private toster: ToastrService,private cs: GlobalService,
  ) {}

  ngOnInit() {
    this.api_service.qr_code_withdraw_show().subscribe((res: any) => {
      this.curretQr = res.image_path;
      this.walletAddress = res.wallet_address;
    });
    this.transactionForm = this.fb.group({
      amount: [
        '',
        [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
      transaction_id: ['',[Validators.required]],
      bank_account_name: [''],
      name_of_bank: [''],
      upi_id: ['',Validators.required],
      bank_ifsc_code: [''],
      inr_amount: [''],
      branch: [''],
      bank_account_no: ['', [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      wallet_address: ['', [Validators.required, validateWalletAddress()]],
    });

    this.api_service.usdt_show().subscribe({
      next: (res: any) => {
        this.usdtPrice = res.usdt_value;
      },
      error: () => {},
    });

  }
  calculationOfUsdt() {
    const val = (this.f.amount.value ? this.f.amount.value : 0)*this.usdtPrice
    this.f.inr_amount.setValue(val);
  }

  get f() {
    return this.transactionForm.controls;
  }
  onSubmit() {
    if (this.transactionForm.valid) {
      this.api_service
        .user_withdraw({ ...this.transactionForm.value })
        .subscribe({
          next: (res: any) => {
            this.cs.userWithdrawalApiCall$.next({isCallApi:true})
            this.toster.success(res.message);
            this.transactionForm.reset();
          },
          error:(err:any)=>{
            this.toster.error(err.message);
          }
        });
    }
  }

  newAmountFunction(amount: number)
  { 
    this.newAmount=amount;
    if(this.newAmount>1000){
      this.f.bank_account_name.setValidators(Validators.required)
      this.f.name_of_bank.setValidators(Validators.required)
      this.f.bank_ifsc_code.setValidators(Validators.required)
      this.f.branch.setValidators(Validators.required)
      this.f.bank_account_no.setValidators(Validators.required)
      this.f.upi_id.setErrors(null)

    }else{
      this.f.bank_account_name.setErrors(null)
      this.f.name_of_bank.setErrors(null)
      this.f.bank_ifsc_code.setErrors(null)
      this.f.branch.setErrors(null)
      this.f.bank_account_no.setErrors(null)
      this.f.upi_id.setValidators(Validators.required)
    }
  }
  checkTransactionHash()
  {

  }


}



