<section class="opacityview ">
  <section class="main_sec">
    <div class="row justify-content-center ">
      <div class="col-12 col-xs-12 col-sm-12 col-md-10 col-xl-10 col-lg-10  ">
        <div class="row justify-content-center">
          <div class="col-md-10  mb-5">
            <div class=" chooseno">
              <div class="row ">
                <div class="col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 m-auto">
                 <div>
                  <img [src]="curretQr" alt="" style="width: 62%;"  />
                 </div>
                 <div>
                  <h6 class="my-2" >Powered by Transak</h6>
                  <p class="m-0" style="text-transform: initial;" >Scan the QR to buy wUSDT from Fiat</p>
                  <p class="m-0" >OR</p>
                  <!-- <input [value]="upi_id" class="form-control" readonly> -->
                  <p>UPI ID :  <b>{{upi_id}}</b></p>
                 </div>
                </div>
                <div class="col-12 col-sm-12 col-xs-12 col-md-8 col-lg-8 col-lx-8">
                  <form [formGroup]="transactionForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                      <!-- <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="">UPI ID</label>
                          <input [value]="upi_id" class="form-control" readonly>
                        </div>
                      </div> -->
                      <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="amount">INR Amount</label>
                          <input type="text" (keyup)="calculationOfUsdt()"  class="form-control" id="amount" formControlName="amount" />
                          <div *ngIf="
                              f.amount?.invalid &&
                              f.amount?.touched
                            ">
                            <div *ngIf="f.amount?.errors?.required" class="alert">
                              Amount is required
                            </div>
                            <div *ngIf="f.amount?.errors?.pattern" class="alert">
                              Amount must be a number
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="amount" style="text-transform: initial;" >wUSDT PRICE</label>
                          <input type="text" class="form-control" readonly id="amount" formControlName="usdt_value" />
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="transaction_id">Transaction ID</label>
                          <input type="text" class="form-control" id="transaction_id" formControlName="transaction_id" />
                          <div *ngIf="
                             f.transaction_id?.invalid &&
                             f.transaction_id?.touched
                            ">
                            <div class="alert" *ngIf="f.transaction_id?.errors?.required">
                              Transaction ID is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                          <label for="wallet_address">Wallet Address</label>
                          <input type="text" class="form-control" id="wallet_address" formControlName="wallet_address" />
                          <div *ngIf="
                            f.wallet_address?.invalid &&
                            f.wallet_address?.touched
                            ">
                            <div class="alert" *ngIf="f.wallet_address?.errors?.required">
                              Wallet Address is required
                            </div>
                            <div class="alert" *ngIf="f.wallet_address?.hasError('invalidAddress')">
                              Invalid Wallet Address
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <!-- <div class="form-group">
                      <label for="walletAddress">Narration</label>
                      <textarea class="form-control" aria-label="With textarea"></textarea>
                    </div> -->
                    <button class="btn  read-more-btn1" [disabled]="transactionForm.invalid">
                      Submit
                      <i
                      *ngIf="isBuyTocken == 1"
                      class="fa fa-spinner fa-spin"
                      style="font-size: 18px"
                    ></i>
                    </button>
                  </form>
                </div>
              </div>


            </div>
          </div>
          <div class="col-md-10">
            <app-user-deposite-list ></app-user-deposite-list>
          </div>
        </div>
      </div>
    </div>

  </section>
</section>
