import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private toster:ToastrService,private api_service:ApiService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if(request.url.includes('qr_code')||request.url.includes('qr_code_withdraw')){
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status == 408) {
            this.toster.error('unauthorize request')
            this.api_service.logOut()
          }
          debugger
          if (error.status == 400) {
            this.toster.error(error.message)
          }
          return throwError(error);
        }),
        finalize(() => {})
      );
    }
    request = request.clone({
      headers: request.headers.set('Content-Type', 'application/json'),
    });
    request = request.clone({
      headers: request.headers.set('charset', 'UTF-8'),
    });

    request = request.clone({
      headers: request.headers.set('Access-Control-Allow-Origin', '*'),
    });
    request = request.clone({
      headers: request.headers.set(
        'X-API-Key',
        '2jzFD63wF8xJz8J6ltjFVgJvZHztMtFfoE0uKlFxP0ay8zx8hlqI836EntOYlniW'
      ),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 408) {
          this.toster.error('unauthorize request')
          this.api_service.logOut()
        }
        debugger
        if (error.status == 400) {
          return throwError(error.error)
        }

        return throwError(error);
      }),
      finalize(() => {})
    );
  }
}
