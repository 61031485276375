<section class="opacityview ">
    <section class="main_sec p-0">
   
      <div class="row">
        <div class="col-md-12 me-auto ms-auto text-center align-item-center">
          <div class="user_card">

            <table class="darktable">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>WALLET ADDRESS</th>
                  <th>TRANSACTION ID</th>
                  <th>wUSDT PRICE</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of userDeposite ; let i=index;">
                    <td>{{item.amount}}</td>
                    <td>{{item.wallet_address?.slice(0,5)}}...{{item.wallet_address?.slice(-5)}}</td>
                    <td>{{item.transaction_id}}</td>
                    <td>{{item.usdt_amount | number}}</td>
                    <td>{{item.status==0 ? 'pending' : item.status==1 ? 'failed' :'Approved' }}</td>
                  </tr>
                  <tr *ngIf="userDeposite.length==0">
                      <td colspan="4">No Deposit Found</td>
                  </tr>
              </tbody>
            </table>
            <ngb-pagination *ngIf="userDeposite.length>0"
              [(page)]="page"
              [maxSize]="4"
              [collectionSize]="totalData"
              (pageChange)="loadPage($event)"
              class="justify-content-center"
            ></ngb-pagination>
          </div>
        </div>
      </div>
   
  </section>
  </section>