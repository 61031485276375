import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/core/services/api.service';
import { GlobalService } from 'src/core/services/global.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  accountAddress: any = '';
  _referalAddress = '';
  isShowbuyFirstNftSpinner: boolean = false;
  isShowbuySecondNftSpinner: boolean = false;
  playerDetails: any;
  getBetLastResultNumber: any = [];
  timestamp: any;
  topEarningUser: any[] = [];
  roundId: any;
  mat4winnerr: any;
  match3winner: any;
  match5winner: any;
  match6Winner: any;
  highestRoundArray : any = {};
  referalLinkurl: any = '';
  referalEaring: any;
  referalErningOne: number = 0;
  totalOfRefferalEarning: any = 0;
  nft200Earning: number = 0;
  pageNo = 1;
  nftId: any;
  globalAmount: any;
  busdbalance: number = 0;
  referalInfo: any;
  match3WinnerList!: any[];
  isLoadingMatch3WinnerList!: number;
  directSaleList: any[] = [];
  isdirectSaleListLoaded: number = 0;
  explorerUrl = environment.exploreUrl;
  @ViewChild('closebuttonreportMatch3') closebuttonreportMatch3: any;
  twoTo5LevelList: any[] = [];
  isTwoTo5LevelListLoaded: number = 0;
  nftTwoHundredList: any[] = [];
  isnftTwoHundredListLoaded: number = 0;
  AccumulatedEarningList: any[] = [];
  IsAccumulatedEarningListLoaded: number = 0;
  rank: any = {};
  gameId: any;
  page: any = 1;
  pageSize: number = 0;
  totalRanking: number = 0;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private cs: GlobalService, private api_service: ApiService, private toastrService: ToastrService) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit(): Promise<void> {
    this.cs.getEtherumContractProvider();
    await this.getOfflineData()
    await this.cs.init();
    this.accountAddress = localStorage.getItem('address');
    this.cs.walletDetails$.subscribe(async (r) => {
      if (this.cs.isValidAddress(r)) {
        this.accountAddress = r;
        // this.getTobEarningUser();
        this.getRank();
        await this.getPlayerDetails();
        await this.getCurrentEarning();
        await this.getMatch4Winner();
        await this.getMatch3Winner();
        await this.getMat5Winner();
        await this.getMatch6Winner();
        await this.getBetLastResult();
      }
    })

    this.referalLinkurl = window.location.href.slice(0, window.location.href.indexOf('/home')) + '/home?referalAddress=';

    this._referalAddress = this.cs.getUplineid(this.activatedRoute);


  }


  scroll() {
    let el = document.getElementById('buyNftFirst')
    el?.scrollIntoView({ behavior: "smooth", block: 'center' });
  }

  async getOfflineData() {
    try {
      this.busdbalance = await this.cs.tokenContract_new.balanceOf(environment.LOTTOGame);
      this.gameId = await this.cs.lottoGameContract_new.getCurrentGameId();
      this.roundId = await this.cs.getRoundIdOffline();
      this.globalAmount = await this.cs.contract_new.globalAmount();
      this.getBetLastResultNumber = await this.cs.lottoGameContract_new.getGameResult(this.gameId - 1);
      this.getTobEarningUser();
      await this.getMatch4WinnerOffline();
      await this.getMatch3WinnerOffline();
      await this.getMat5WinnerOffline();
      await this.getMatch6WinnerOffline();
      await this.getRoundId();
    } catch (error) {

    }

  }




  async buyFirstNft() {
    let walletAddress = localStorage.getItem('address');
    if(walletAddress==environment.zero_address){
      this.toastrService.error("Invalid referral");

    }
    else{
    if (walletAddress) {
      try {
        this.isShowbuyFirstNftSpinner = true;
        const tokenBalance = await this.cs.balanceOf(walletAddress)
         const nftPrice = await this.cs.nftBuyAmount()
         if((parseInt(tokenBalance._hex,16)<parseInt(nftPrice._hex,16))){
          this.toastrService.error(`required token  balance is ${parseInt(nftPrice._hex,16)/1e18}`);
          this.isShowbuyFirstNftSpinner = false
         }else{
           let check = await this.cs.checkAllowance(walletAddress);
           if (parseInt(check._hex,16) < parseInt(nftPrice._hex,16)) {
             let approve = await this.cs.approve(nftPrice+'');
             await approve.wait(2);
           }

           let hex = await this.cs.buyFirstNft(this._referalAddress);
           await hex.wait(2);
           this.isShowbuyFirstNftSpinner = false;
           this.toastrService.success('Nft purchased successfully')
          await this.getPlayerDetails();
         }

      } catch (e: any) {
        this.toastrService.error(`${e.reason ? e.reason : 'execution reverted: BEP20: transfer amount exceeds balance'}`);
        this.isShowbuyFirstNftSpinner = false;
      }
    } else {
      this.toastrService.warning(`Please connect wallet first.`);
    }
  }

  }


  async getPlayerDetails() {
    try {

        this.playerDetails = await this.cs.getPlayerDetails(this.accountAddress);
        this.referalInfo = await this.cs.contract.referralEarningInfo(this.accountAddress);


    } catch (error: any) {
      console.log(error);

    }
  }

  async buySecondNft() {
    try {

      this.isShowbuySecondNftSpinner = true;
      const tokenBalance = await this.cs.balanceOf(this.accountAddress)
         const nftPrice = await this.cs.secondNftBuyAmount()
         if((parseInt(tokenBalance._hex,16)<parseInt(nftPrice._hex,16))){
          this.toastrService.error(`required token  balance is ${parseInt(nftPrice._hex,16)/1e18}`);
          this.isShowbuySecondNftSpinner = false
         }else{
           let check = await this.cs.checkAllowance(this.accountAddress);
           if (parseInt(check._hex,16) < parseInt(nftPrice._hex,16)) {
             let approve = await this.cs.approve(nftPrice);
             await approve.wait(2);
           }
           let hex = await this.cs.buySecondNft();

           await hex.wait(2);
           this.isShowbuySecondNftSpinner = false;
           this.toastrService.success('Nft purchased successfully')
          await this.getPlayerDetails();
         }

    } catch (e: any) {
      this.toastrService.error(e.reason);
      this.isShowbuySecondNftSpinner = false;
    }
  }

  withdraw() {
    this.api_service.getActiveNftId(this.accountAddress).subscribe({
      next: async (res: any) => {
        if (res.nftIds == 0) {
          this.toastrService.error(`No nft withdrawal found...`);
          return;
        }
        this.withdrawFunct(res.nftIds);
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  async withdrawFunct(id: any) {

    try {
      await this.cs.contract.withdraw(id);
    }
    catch (e: any) {
      this.toastrService.error(e.error?.data?.message)
    }
  }

  async getBetLastResult() {
    try {
      this.getBetLastResultNumber = await this.cs.lottoGameContract.getGameResult(this.gameId - 1);
      setTimeout(() => {
        this.getBetLastResult();
      }, 30000);
    } catch (error) {
      console.log(error);

    }

  }

  isTimeExpired(event: any) {
    if (event == 'expired') {
      setTimeout(() => {
        location.reload();
      }, 10000);

    }
  }


  // async getBlockNumber() {
  //   var web3Provider = new Web3.providers.HttpProvider(environment.rpc_url);
  //   var web3 = new Web3(web3Provider);
  //   let block: any;
  //   let blockHash: any;
  //   await web3.eth.getBlockNumber().then((data: any) => {
  //     block = data;
  //   });
  //   await web3.eth.getBlock(block).then((data: any) => {
  //     blockHash = data?.hash;
  //     this.timestamp = data.timestamp;

  //   });
  // }


  getTobEarningUser() {
    this.api_service.topEarningUser(this.page).subscribe({
      next: (res: any) => {

        this.topEarningUser = res.data;
        this.totalRanking = res.total;
        this.pageSize = res.perPage;
      },
      error: (err: any) => {

      }
    })
  }

  loadPage(event: number) {
    this.page = event;
    this.getTobEarningUser();
  }
  async getRoundId() {
    this.roundId = await this.cs.getRoundId();
    this.pageNo = this.roundId;
    this.getHighestRound(this.roundId)
  }

  async fetchRound(roundId: any) {
    this.pageNo = roundId;
    await this.getHighestRound(roundId)
  }

  async getMatch4Winner() {
    try {
      this.mat4winnerr = await this.cs.getWinnerCount(4);
    } catch (error) {
      console.log(error);
    }
  }

  async getMatch3Winner() {
    try {
      this.match3winner = await this.cs.getWinnerCount(3)
    } catch (error) {
      console.log(error);

    }
  }

  async getMat5Winner() {
    try {
      this.match5winner = await this.cs.getWinnerCount(5);
    } catch (error) {
      console.log(error);
    }
  }

  async getMatch6Winner() {
    try {
      this.match6Winner = await this.cs.getWinnerCount(6);
    } catch (error) {
      console.log(error);
    }
  }

  //offline
  async getMatch4WinnerOffline() {
    try {
      this.mat4winnerr = await this.cs.getWinnerCountOffline(4);
    } catch (error) {
      console.log(error);
    }
  }

  async getMatch3WinnerOffline() {
    try {
      this.match3winner = await this.cs.getWinnerCountOffline(3)
    } catch (error) {
      console.log(error);

    }
  }


  async getMat5WinnerOffline() {
    try {
      this.match5winner = await this.cs.getWinnerCountOffline(5);
    } catch (error) {
      console.log(error);
    }
  }

  async getMatch6WinnerOffline() {
    try {
      this.match6Winner = await this.cs.getWinnerCountOffline(6);
    } catch (error) {
      console.log(error);
    }
  }

  async getHighestRound(id: any) {
    try {
      this.highestRoundArray = await this.cs.getHighestRef(id);
    } catch (error) {
      console.log(error);
    }
  }



  parseIntF(hex: any) {
    return parseInt(hex, 16)
  }




  async getCurrentEarning() {
    try {

      this.referalEaring = await this.cs.contract.playerEarning(this.accountAddress);

    } catch (error) {

    }
  }

  copy(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastrService.success("Copied.");
  }
  getMatch3WinnerReport(numberReport: number) {
    this.match3WinnerList = [];
    this.isLoadingMatch3WinnerList = 0;
    this.api_service.winnerReportList(numberReport).subscribe({
      next: (res: any) => {
        this.isLoadingMatch3WinnerList = 1;
        this.match3WinnerList = res.data;
        if (res.data.length == 0) {
          this.isLoadingMatch3WinnerList = 2;
        }
      },
      error: (err: any) => {
        this.isLoadingMatch3WinnerList = 2;
      }
    })
  }


  openDirectSaleModal(type: number) {
    this.api_service.getEarningReport(type, this.accountAddress).subscribe({
      next: (res: any) => {
        this.directSaleList = res.data;
        this.isdirectSaleListLoaded = 1;
        if (res.data.length == 0) {
          this.isdirectSaleListLoaded = 2;
        }
      },
      error: (err: any) => {
        this.isdirectSaleListLoaded = 2;
      }
    })
  }

  open2to5LevelModal(type: number) {
    this.api_service.getEarningReport(type, this.accountAddress).subscribe({
      next: (res: any) => {
        this.twoTo5LevelList = res.data;
        this.isTwoTo5LevelListLoaded = 1;
        if (res.data.length == 0) {
          this.isTwoTo5LevelListLoaded = 2
        }
      },
      error: (err: any) => {
        this.isTwoTo5LevelListLoaded = 2
      }
    })
  }

  openNft200Modal(type: number) {
    this.api_service.getEarningReport(type, this.accountAddress).subscribe({
      next: (res: any) => {
        this.nftTwoHundredList = res.data;
        this.isnftTwoHundredListLoaded = 1;
        if (res.data.length == 0) {
          this.isnftTwoHundredListLoaded = 2
        }
      },
      error: (err: any) => {
        this.isnftTwoHundredListLoaded = 2
      }
    })
  }

  openAccumulatedEarningModal(type: any) {
    this.api_service.getEarningReport(type, this.accountAddress).subscribe({
      next: (res: any) => {
        this.AccumulatedEarningList = res.data;
        this.IsAccumulatedEarningListLoaded = 1;
        if (res.data.length == 0) {
          this.IsAccumulatedEarningListLoaded = 2
        }
      },
      error: (err: any) => {
        this.IsAccumulatedEarningListLoaded = 2
      }
    })
  }

  getRank() {
    this.api_service.getRank(this.accountAddress).subscribe({
      next: (res: any) => {

        this.rank = res;
      },
      error: (err: any) => {

      }
    })
  }

}
