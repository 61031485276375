import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/core/services/global.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

@Component({
  selector: 'app-coundown-timer-two',
  templateUrl: './coundown-timer-two.component.html',
  styleUrls: ['./coundown-timer-two.component.scss']
})
export class CoundownTimerTwoComponent implements OnInit {
  @Input() gameResultTime: any;
  CurrentBlockTime: any;
  @Input() FromTab :any;
  days: any = 0;
  hours: any = 0;
  minutes: any = 0;
  seconds: any = 0;
  timeUp: boolean = false;
  @Output() timexpired = new EventEmitter();
  epocTime :any;
  constructor(private cd: ChangeDetectorRef, private globalService: GlobalService) { }

  async ngOnInit(): Promise<void> {
     await this.getBlockNumber();
     if(this.epocTime){
      this.CurrentBlockTime = this.epocTime;
     }
    let availableTimeToClaimJackpot:any  = await this.globalService.getAvailableTimeToClaimJackpot();
    let EndTime :any;
    let availableTimeToClaim :any = await this.globalService.getAvailableTimeToClaim();
 
    if(this.FromTab == 'activeGame'){
    EndTime = parseInt(this.gameResultTime) + parseInt(availableTimeToClaim);
    // EndTime = new Date(EndTime * 1000);
    }else{
      EndTime = parseInt(this.gameResultTime) + parseInt(availableTimeToClaimJackpot) + parseInt(availableTimeToClaim);
      // EndTime = new Date(EndTime * 1000);
    }
    

    let Currentdate = new Date(this.CurrentBlockTime * 1000);

    let x = setInterval(() => {
      // EndTime = new Date(EndTime.getTime() - 1000)
      var distance = (EndTime*1000) - (this.CurrentBlockTime * 1000);


      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.CurrentBlockTime++;
      if (this.hours < 0) {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        this.timexpired.emit('expired')
        clearInterval(x);

      }

    }, 1000)
  }


  async getBlockNumber(){
    var web3Provider = new Web3.providers.HttpProvider(environment.rpc_url);
    var web3 = new Web3( web3Provider );
    let block :any;
    let blockHash :any;
    await web3.eth.getBlockNumber().then((data:any)=>{
      block = data;
    });
    await web3.eth.getBlock(block).then((data:any)=>{
      blockHash = data.hash;
      this.epocTime = data.timestamp;
      
    });
  }



}
