import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/core/services/api.service';
import { GlobalService } from 'src/core/services/global.service';

@Component({
  selector: 'app-user-deposite-list',
  templateUrl: './user-deposite-list.component.html',
  styleUrls: ['./user-deposite-list.component.scss'],
})
export class UserDepositeListComponent implements OnInit {
  userDeposite: any[] = [];
  address: any;
  page: number = 1;
  totalData = 0
  constructor(private api_service:ApiService,private globalService:GlobalService) {}

  ngOnInit(): void {
    this.address = localStorage.getItem('address');
    this.getUserDepositeList();
    this.globalService.userDepositeApiCall$.subscribe(
      (res)=>{
        if(res.isCallApi){
          this.getUserDepositeList()
        }
      }
    )
  }
  getUserDepositeList(){
    this.api_service.user_deposite_list(this.address,this.page).subscribe((res:any)=>{
      this.userDeposite = res.data
      this.totalData = res.total
    })
  }
  loadPage(event:number){
    this.page = event
    this.getUserDepositeList()
  }
}
