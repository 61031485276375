import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/core/services/api.service';
import { ethers } from 'ethers';


@Component({
  selector: 'app-view-more-details',
  templateUrl: './view-more-details.component.html',
  styleUrls: ['./view-more-details.component.scss']
})
export class ViewMoreDetailsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ViewMoreDetailsComponent>,@Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {
    console.log(this.data);

  }

}
