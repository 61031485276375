import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/core/services/api.service';
import { GlobalService } from 'src/core/services/global.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss']
})
export class BackofficeComponent implements OnInit {
  getBetLastResultNumber: any[] = [];
  transactionHashBetResultNumber: any = '';
  walletAddress: any;
  userBetList: any[] = [];
  exploreUrl = environment.exploreUrl;
  timestamp: any;
  nftList = new Array();
  referalEaring :any;
  referalErningOne:number = 0;
  isLastDraw:number=0;
  isUserBetList :number = 0;
  isNftListVisible: number = 0;
  referalone:any=[]
  referalL :any[]=[];
  Earing:number=0;
  constructor(@Inject(DOCUMENT) private document: Document, 
  private api_service: ApiService,
  private ngxUiLoader:NgxUiLoaderService,
  private globalService:GlobalService) { }

  async ngOnInit(): Promise<void> {
    this.walletAddress = localStorage.getItem('address');
    this.globalService.walletDetails$.subscribe((r) => {
      if (this.globalService.isValidAddress(r)) {
        this.walletAddress = r;
      }
     })

    await this.getBlockNumber();
    this.getBetLastResult();
    this.getUseBetList();
    this.getCurrentEarning();
    this.getNftList();
    this.getReferalEaringInfo(this.walletAddress);
    this.getEarning(this.walletAddress);
  }


  // @HostListener('window:scroll', [])
  // onWindowScroll() {

  //   if (document.body.scrollTop > 20 ||
  //     document.documentElement.scrollTop > 20) {
  //     this.document.getElementById('dynamic')?.classList.add('withdrawalstart-sec1')
  //   } else {
  //     this.document.getElementById('dynamic')?.classList.remove('withdrawalstart-sec1');
  //   }
  // }
  getEarning(walletAddress:string){
    this.api_service.getUserEarning(walletAddress).subscribe({
      next:(res:any)=>{
        this.Earing = res.earning;
      },
      error:(err:any)=>{

      }
    })
  }

  async getBetLastResult() {

    let gameId = await this.globalService.lottoGameContract.getCurrentGameId();
    
    let result:any = await this.globalService.lottoGameContract.getGameResult(gameId-1);
    this.getBetLastResultNumber = result.randomNos;
    this.isLastDraw = 1


    // this.api_service.getBetLastResult().subscribe({
    //   next: (res: any) => {
    //     if(res.data){
    //       this.getBetLastResultNumber = res.data.number;
    //       this.transactionHashBetResultNumber = res.data.transactionHash;
    //       this.isLastDraw = 1
    //     }else{
    //       this.isLastDraw = 2
    //     }
       

    //   },
    //   error: (e: any) => {
    //     this.isLastDraw = 2
    //   }
    // })
  }

  getUseBetList() {
    this.api_service.userBetList(this.walletAddress).subscribe({
      next: (res: any) => {
        this.userBetList = res.data;
        this.isUserBetList = 1;
        if(res.data.length == 0){
          this.isUserBetList = 2;
        }
        if (this.timestamp) {
          let game_startTime = 1669723200;
          let gameId = (Math.round((Math.round(this.timestamp) / 300 - Math.round(game_startTime) / 300)) + 1)
          let oldtime = new Date(this.timestamp * 1000)

          let t = (Math.round(gameId) * 300) + Math.round(game_startTime);
          let Currentdate = new Date(t * 1000)
          this.userBetList.forEach((element:any)=>{
            let time = new Date(element.time *1000)
              if(time > oldtime && time < Currentdate){
                element.status = 'Playing';
              }
               if(time < oldtime){
                element.status = 'Expired';
              }
               if(time > oldtime){
                element.status = 'Playing';
              }
            
          })
        }
      },
      error: (err: any) => {
        this.isUserBetList = 2;
      }
    })
  }

  async getBlockNumber() {
    var web3Provider = new Web3.providers.HttpProvider(environment.rpc_url);
    var web3 = new Web3(web3Provider);
    let block: any;
    let blockHash: any;
    await web3.eth.getBlockNumber().then((data: any) => {
      block = data;
    });
    await web3.eth.getBlock(block).then((data: any) => {
      blockHash = data.hash;
      this.timestamp = data.timestamp;
      // console.log(timestamp);

    });
  }
  totalOfRefferalEarning :any = 0;
  nft200Earning :number =0;
  async getCurrentEarning(){
    try {
      this.referalEaring = await  this.globalService.getCurrentRefferalInfo(this.walletAddress);
      this.referalErningOne = this.referalEaring?.referralEarningsL[0]._hex;
      // console.log("referalErningOne =>",this.referalErningOne);
      this.nft200Earning = this.referalEaring.nft200Earning._hex;
      // 
      for(let i=1;i<this.referalEaring.referralEarningsL.length;i++){
          this.totalOfRefferalEarning = this.totalOfRefferalEarning + parseInt(this.referalEaring.referralEarningsL[i],16)
      }
    } catch (error) {
      
    }
  }


  parseIntF(hex:any){
    return parseInt(hex,16)
  }

 



  getNftList() {
    
    let address = localStorage.getItem('address');
    // this.api_service.getNftFromMoralis(address).subscribe({
    //   next: (res: any) => {
    //     this.nftList = res.nftIds;
       
    //     this.isNftListVisible = 1;
    //     if(res.nftIds.length == 0){
    //       this.isNftListVisible = 2;
    //     }
    //   },
    //   error: (err: any) => {
    //     this.nftList = [];
    //     this.isNftListVisible = 2;
    //   }
    // });


  }

  async getReferalEaringInfo(address:any){
   let info = await this.globalService.getReferalEarningInfo(address);
    this.referalone = info.referrals;
    this.referalL = info.referralEarningsL;
  }
}
