<section class="withdrawal-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12 me-auto ms-auto text-center align-item-center">
        <h2 >TOTAL WITHDRAWN <span>{{Earing/1e6}}</span> wUSDT</h2>
        <div  class="withdrawalstart-sec">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" data-aos="fade-up" data-aos-duration="1000">
              <h2 data-aos="fade-up" data-aos-duration="500">Draw start in:</h2>
              <div class="counter" >
                <div class="digital" style="font-size: 84px" >
                  
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" data-aos="fade-up" data-aos-duration="1000">
              <h2>last draw:</h2>
              <div class="series" style="margin: 20px 0">
                <ng-container *ngIf="isLastDraw == 0">
                  <span>Loading...</span>
                </ng-container>
                <ng-container *ngIf="isLastDraw == 1">
                  <span *ngFor="let item of getBetLastResultNumber">{{
                    item
                  }}</span>
                </ng-container>
                <ng-container *ngIf="isLastDraw == 2">
                  <span>Not found...</span>
                </ng-container>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-auto " data-aos="fade-up" data-aos-duration="3000">
              <div class="btn-format">
                <a [routerLink]="['/lottery']" class="btn btn-playlottery"
                  >Play Lottery</a
                >
                <!-- <a
                  href="{{ exploreUrl }}{{ transactionHashBetResultNumber }}"
                  target="_blank"
                  class="btn btn-draws"
                  >Verify Draws</a
                > -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="lotteryreward-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12 me-auto ms-auto text-center align-item-center">
        <h4 class="lotteryname mb-4" data-aos="fade-up" data-aos-duration="500" style="color: #bd04c6;" >LOTTERY REWARDS = 500 USDT</h4>
        <h2 class="lotterysubname mb-4" data-aos="fade-up" data-aos-duration="1000">ALL MY NFTS TICKETS</h2>
        <h6 data-aos="fade-up" data-aos-duration="1500">reports</h6>
        <table class="table table-borderless text-center contenttable" data-aos="fade-up" data-aos-duration="2000">
          <thead>
            <tr>
              <th class="tablehead">Sr.No</th>
              <th class="tablehead">NFT NUMBERS</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="isUserBetList == 1">
              <tr *ngFor="let item of nftList;let i=index;">
                <td class="tablehead">{{i+1}}</td>
                <td class="tablehead">
                  {{ item.token_id }}
                </td>
                
              </tr>
            </ng-container>

            <tr *ngIf="isUserBetList == 0">
              <td colspan="2" >loading...</td>
            </tr>
            <tr *ngIf="isUserBetList == 2">
              <td colspan="2" >Not Found...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12 me-auto ms-auto text-center align-item-center">
        <div class="currentearning">
          <h2 class="currenthead">CURRENT EARNINGS</h2>
          <table class="table table-borderless text-center ">
            <tbody>
              <tr>
                <td class="currentsub">DIRECT SALE</td>
                <td class="currentsub">
                  {{ parseIntF(referalErningOne) /1e6}}
                </td>
                <td class="currentsub1">AVAILABLE TO WITHDRAW</td>
              </tr>
              <tr>
                <td class="currentsub">2 - 5 LEVEL</td>
                <td class="currentsub">{{ totalOfRefferalEarning /1e6}}</td>
                <td class="currentsub">Not AVAILABLE TO WITHDRAW</td>
              </tr>
              <tr>
                <td class="currentsub">NFT200</td>
                <td class="currentsub">{{ parseIntF(nft200Earning)/1e6 | number:'0.0-4' }}</td>
                <td class="currentsub1">AVAILABLE TO WITHDRAW</td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-withdraw">WITHDRAW</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-12 me-auto ms-auto text-center align-item-center">
        <div class="referralstrutuce-sec">
          <h1 class="currenthead mt-5" data-aos="fade-up" data-aos-duration="2000">REFERRAL STRUCTURE</h1>
          <h6 data-aos="fade-up" data-aos-duration="2500">Reports</h6>
          <table class="table-borderless table text-center contenttable" >
            <thead data-aos="fade-up" data-aos-duration="3000">
              <tr>
                <th class="currentsub"><u>LEVEL</u></th>
                <th class="currentsub"><u>TOTAL REFERRALS</u></th>
                <th class="currentsub"><u>PROFITS</u></th>
              </tr>
            </thead>
            <tbody data-aos="fade-up" data-aos-duration="2000">
              <tr *ngFor="let item of referalone;let i=index;">
                <td class="levels">Level {{i+1}}</td>
                <td class="levels">{{parseIntF(item._hex)}}</td>
                <td class="levels">{{referalL[i]/1e6 | number:'0.0-4'}} wUSDT</td>
              </tr>
              <tr *ngIf="referalone.length == 0">
                <td colspan="3">
                  No Data Found.
                </td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="nftsec">
  <div class="container">
    <div class="row">
      <div class="col-md-12 me-auto ms-auto text-center align-item-center">
        <h1 class="currenthead" data-aos="fade-up" data-aos-duration="1000">NFT200 REWARDS</h1>
        <h4 class="mb-5" data-aos="fade-up" data-aos-duration="1500">TOTAL NFT200 = 2200 USDT</h4>
        <div
          class="row" style="justify-content: center;" data-aos="fade-up" data-aos-duration="2000"
         
        >
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" >
            <div class="card" >
              <div class="card-img">
                <img src="assets\img\image\payment.png" style="width: 80%;"  />
              </div>
              <p>PAYMENTS</p>
              <p>800 wUSDT</p>
              <h3>4</h3>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <div class="card">
              <div class="card-img">
                <img src="assets\img\image\payment.png"  style="width: 80%;"/>
              </div>
              <p>PAYMENTS</p>
              <p>800 wUSDT</p>
              <h3>4</h3>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <div class="card">
              <div class="card-img">
                <img src="assets\img\image\payment.png"  style="width: 80%;"/>
              </div>
              <p>PAYMENTS</p>
              <p>800 wUSDT</p>
              <h3>4</h3>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <div class="card">
              <div class="card-img">
                <img src="assets\img\image\payment.png"  style="width: 80%;"/>
              </div>
              <p>PAYMENTS</p>
              <p>800 wUSDT</p>
              <h3>4</h3>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <div class="">
              <div class="card-img">
                <img src="assets/img/image/41.png" style="width: 80%;" />
              </div>
              <p>PAYMENTS</p>
              <p>800 wUSDT</p>
              <h3>4</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
