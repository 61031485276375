<section class="info-sec">
    <div class="container">
      <div class="col-md-10 me-auto ms-auto text-center align-item-center">
        <h1>
          JOIN THE MOST AMBITIOUS PROJECT OF2023, THE <br />FIRST GLOBAL
          LOTTERY WHERE <br />YOU WIN EVEN IF YOU'RE NOT LUCKY
        </h1>
      </div>
    </div>
  </section>
  <section class="bet-video">
    <div class="container">
      <div class="row"></div>
    </div>
  </section>
  <section class="safe-sec">
    <div class="container">
      <div class="row me-auto ms-auto text-center align-item-center">
        <div class="col-md-4 col-sm-4 col-md-4 col-xs-4 col-4">
          <img src="assets\img\image\safe.png" />
          <h4>100% safe</h4>
        </div>
        <div class="col-md-4 col-sm-4 col-md-4 col-xs-4 col-4">
          <img src="assets\img\image\autonomous.png" />
          <h4>100% Autonomous</h4>
        </div>
        <div class="col-md-4 col-sm-4 col-md-4 col-xs-4 col-4">
          <img src="assets\img\image\transparent.png" />
          <h4>100% Transparent</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 me-auto ms-auto text-center align-item-center">
          <div class="social-channel">
            <img src="assets\img\image\telegram.png" />
            <h4>Telegram channel</h4>
            <p>
              Enter our Official channel, participate for FREE raffles and
              prizes
            </p>
            <a href="#" class="btn btn-click">Click Here</a>
          </div>
        </div>
      </div>
    </div>
  </section>