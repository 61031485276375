import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/core/services/api.service';
import { environment } from 'src/environments/environment';
import { ViewMoreDetailsComponent } from '../withdrawal-token/view-more-details/view-more-details.component';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/core/services/global.service';


@Component({
  selector: 'app-user-withdrawal-list',
  templateUrl: './user-withdrawal-list.component.html',
  styleUrls: ['./user-withdrawal-list.component.scss']
})
export class UserWithdrawalListComponent implements OnInit {

  userDeposite: any[] = [];
  address: any;
  page = 1
  totalData = 0
  explorerUrl = environment.exploreUrl;
  constructor(private api_service:ApiService,private dialogRefOpen:MatDialog,private cs :GlobalService) {}

  ngOnInit(): void {
    this.address = localStorage.getItem('address');
    this.user_withdraw_list()
    this.cs.userWithdrawalApiCall$.subscribe(res=>{
      if(res.isCallApi){
        this.user_withdraw_list()
      }
    })
  }
  user_withdraw_list(){
    this.api_service.user_withdraw_list(this.address,this.page).subscribe((res:any)=>{
      this.userDeposite = res.data
      this.totalData = res.total
    })
  }
  loadPage(event:number){
    this.page = event
    this.user_withdraw_list()
  }
  _viewMoreDetails(item:any) {
    this.dialogRefOpen.open(ViewMoreDetailsComponent,{
      data:item
    })
  }
}
