import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/core/services/global.service';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/core/services/api.service';
import Web3 from 'web3';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isConnected!: boolean;
  address: string = '';
  isShowbuyFirstNftSpinner: boolean = false;
  playerDetails: any;
  timestamp: any;
  isShowbuySecondNftSpinner: boolean = false;
  headerData: any = {};
  lastDayPlayersCount: number = 0;
  getBetLastResultNumber: any = [];
  isgetBetLastResultNumberLoading: number = 0;
  latestGameId: any;
  referalAddress: any;
  busdbalance: any;
  explorerUrl = environment.exploreUrl;
  jackpotContract = environment.LOTTOGame;
  tokenContract = environment.token_address;
  showWithdrawal: boolean=false;

  constructor(
    private apiService: ApiService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private cs: GlobalService,
    private router: Router
  ) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    let element = document.querySelector('.header1') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar1');
    } else {
      element.classList.remove('navbar1');
    }
  }

  async ngOnInit(): Promise<void> {
    this.cs.getEtherumContractProvider();
    await this.fetOfflineData();
    await this.cs.init();

    this.cs.walletDetails$.subscribe((r) => {
      if (this.cs.isValidAddress(r)) {
        this.address = r;
        this.isConnected = true;
      }
    });

    this.getHeaderData();
    this.getPlayerDetails();
    this.referalAddress = this.cs.getUplineid(this.activatedRoute);
    this.getUserDepositeList();
    this.cs.userDepositeApiCall$.subscribe(
      (res)=>{
        if(res.isCallApi){
          this.getUserDepositeList()
        }
      }
    )
  }

  async fetOfflineData() {
    const balance = await this.cs.tokenContract_new.balanceOf(
      environment.LOTTOGame
    );
    this.busdbalance = parseInt(balance._hex, 16) / 1e18;
  }

  async connect() {
    await this.cs.connectContract();
    if (this.isConnected) {
      this.router.navigate(['/home']);
    }
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/home']);
    this.isConnected = false
  }
  async buyFirstNft() {
    if (this.referalAddress == environment.zero_address) {
      this.toastrService.error('Invalid referral');
    } else {
      try {
        this.isShowbuyFirstNftSpinner = true;

        let check = await this.cs.checkAllowance(this.address);
        let nftPrice = ethers.utils.parseEther('50');
        if (check._hex < nftPrice._hex) {
          let approve = await this.cs.approve(nftPrice);
        }
        let hex = await this.cs.buyFirstNft(this.referalAddress);
        await hex.wait(2);
        this.isShowbuyFirstNftSpinner = false;
        this.toastrService.success('Nft purchased successfully');
        this.playerDetails = await this.cs.getPlayerDetails(this.address);
      } catch (e: any) {
        this.toastrService.error(e.reason);
        this.isShowbuyFirstNftSpinner = false;
      }
    }
  }

  async buySecondNft() {
    try {
      this.isShowbuySecondNftSpinner = true;
      let check = await this.cs.checkAllowance(this.address);
      let nftPrice = ethers.utils.parseEther('200');
      if (check._hex < nftPrice._hex) {
        let approve = await this.cs.approve(nftPrice);
      }
      let hex = await this.cs.buyFirstNft(this.referalAddress);

      await hex.wait(2);
      this.isShowbuySecondNftSpinner = false;
      this.toastrService.success('Nft purchased successfully');
    } catch (e: any) {
      this.toastrService.error(e.reason);
      this.isShowbuySecondNftSpinner = false;
    }
  }

  async getPlayerDetails() {
    try {
      if (this.address) {
        this.playerDetails = await this.cs.contract.player(this.address);
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  disconnect() {
    this.cs.disconnectMetamaskWallet();
  }

  getHeaderData() {
    this.apiService.header_data().subscribe({
      next: (res: any) => {
        this.headerData = res;
      },
      error: (er: any) => {},
    });
  }

  async buyNftfist() {
    if (this.referalAddress == environment.zero_address) {
      this.toastrService.error('Invalid referral');
    } else {
      if (this.address) {
        try {
          this.isShowbuyFirstNftSpinner = true;

          let check = await this.cs.checkAllowancetest(this.address);
          let nftPrice = 50000000;
          if (check._hex < nftPrice) {
            let approve = await this.cs.approvetest(nftPrice, this.address);
            await approve.wait(2);
          }

          let hex = await this.cs.buyFirstNft(this.referalAddress);
          await hex.wait(2);
          this.isShowbuyFirstNftSpinner = false;
          this.toastrService.success('Nft purchased successfully');
          this.getPlayerDetails();
        } catch (e: any) {
          this.toastrService.error(
            `${
              e.reason
                ? e.reason
                : 'execution reverted: BEP20: transfer amount exceeds balance'
            }`
          );
          this.isShowbuyFirstNftSpinner = false;
        }
      } else {
        this.toastrService.warning(`Please connect wallet first.`);
      }
    }
  }

  upgatetoVip() {
    this.router.navigate(['/home'], { queryParams: { buySecondNft: true } });
  }
  isTimeExpired(event: any) {
    if (event == 'expired') {
      setTimeout(() => {
        location.reload();
      }, 10000);
    }
  }

  getUserDepositeList(){
    this.apiService.user_deposite_list(this.address,1).subscribe((res:any)=>{
      let count = res?.data.length;
      if(count > 0)
      {
        this.showWithdrawal=true
      }
    })
  }

  // async getBlockNumber(){
  //   var web3Provider = new Web3.providers.HttpProvider(environment.rpc_url);
  //   var web3 = new Web3( web3Provider );
  //   let block :any;
  //   let blockHash :any;
  //   await web3.eth.getBlockNumber().then((data:any)=>{
  //     block = data;
  //   });
  //   await web3.eth.getBlock(block).then((data:any)=>{
  //     blockHash = data.hash;
  //     this.timestamp = data.timestamp;

  //   });
  // }
}
