<footer class="footer">
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-md-4 col-xs-12 col-sm-12 col-12"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <div
            class="footer_1"
            style="display: grid;
            flex-direction: row;
            /* float: right; */
            grid-template-columns: auto auto auto;
            gap: 29px;"
          >
            <a target="_blank" [href]="baseUrl+'address/'+nftAdress">
              <h1 style="font-size: 12px;" >NFT</h1>
              <div class="footerp1" style="display: flex">
                <img src="assets\img\image\footer.png" />
                
              </div>
            </a>

            <a target="_blank" [href]="baseUrl+'address/'+lottoAdress">
              <h1 style="font-size: 12px;">SkyLotto</h1>
              <div class="footerp1" style="display: flex">
                <img src="assets\img\image\footer.png" />
                
              </div>
            </a>
            <!--  -->
           <a target="_blank" [href]="baseUrl+'address/'+lottogameAddress">
            <h1 style="font-size: 12px;">SkyLottoGame</h1>
            <div class="footerp1" style="display: flex">
              <img src="assets\img\image\footer.png" />
             
            </div>
           </a>
          </div>
          <ul class="media">
            <li>
              <a href="#"><img src="assets/img/image/chainlink.png" /></a>
            </li>
            <li>
             <a href="#"> <img src="assets/img/image/wyzth.png" style="    width: 15%;" /></a>
            </li>
            <li>
             <a href="#"> <img src="assets/img/image/MetaMask.png" style="width: 44px" /></a>
            </li>
            
            <li >
              <a href="https://github.com/Community-Web3/SkyLotto "><img src="assets/img/image/GitHub.jpg" alt="" style="width: 44px; border-radius: 50%;"></a>
            </li>
            <!-- <li>
              <img src="assets\img\image\badge.png" />
            </li>
            <li>
              <img src="assets\img\image\number-20.png" />
            </li> -->
          </ul>
        </div>
        <div class="col-md-4 col-xs-12 col-sm-12 col-12">
          <div class="footer_2">
           <a href="https://t.me/dappsupportgroup" target="_blank" >
            <h2>
              <img src="assets/img/image/telegram-app.png" alt=""> Join
              our Telegram
            </h2>
           </a>
            <p class="text-center" >for information,prizes and questions</p>
            <!-- <ul class="socialmedia">
              <li>
               <a href="https://www.facebook.com/groups/Skylottoclub" target="_blank">
                <img src="assets\img\image\facebook .png" />
               </a>
              </li>
             
              <li>
              <a href="https://www.instagram.com/Sky_lotto/" target="_blank">
                <img src="assets\img\image\instagram.png" />
              </a>
              </li>
              <li>
               <a href="https://www.youtube.com/channel/UCarV7oiWLZ0Wn0DGJxC2vzA" target="_blank"> <img src="assets\img\image\youtube.png" /></a>
              </li>
              <li>
                <a href="https://t.me/Skylotto" target="_blank"> <img src="assets/img/image/telegram.png" /></a>
               </li>
               <li>
                <a href="https://www.tiktok.com/@Skylotto" target="_blank"> <img src="assets/img/image/tiktok.png" /></a>
               </li>
               <li>
                <a href=" https://twitter.com/incas_nft" target="_blank"> <img src="assets/img/image/twiter.png" /></a>
               </li>
            </ul> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 me-auto ms-auto text-center align-item-center">
          <div class="notice_sec">
            <h6>Notice:</h6>
            <p>
              You must be older than 18 year old to interact with this
              SmartContract.This System is not centralized,it is based on 100%
              SmartContract,100% verified so everybody can read it and study
              ir before interact with the interface.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>